import React, { useContext } from 'react';
import './betathankyou.css';
import { useLocation } from 'react-router-dom';
import { UserContext } from "../../context/FormDataContext";
const BetaThankyou = () => {
  const location = useLocation();

  console.log("BetaThankyou", location.state);
  const { userData } = useContext(UserContext);
  console.log("Beta thank you",userData)

  return (
    <div>
      <main className="main betathankyou">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-xs-12 col-md-12 col-lg-8 p-0 mx-auto">
              <div className="row center-row align-items-center">
                <div className="col-12 p-md-0 main-logo">
                  <a href="/">
                    <img
                      src="/img/beta-signup/mobile-logo.png"
                      className="img-fluid"
                      alt="feature img"
                    />
                  </a>
                </div>
                <div className="col-10 text-md-center mx-auto">
                  <div className="row">
                    <div className="col-12 p-md-0">
                      <div className="img-section text-center">
                        <img
                          src="/img/beta-signup/Featured.png"
                          className="img-fluid"
                          alt="feature img"
                        />
                      </div>
                      <div className="img-section text-center py-4">
                        <img
                          src="/img/rentid-logo.svg"
                          className="img-fluid"
                          alt="feature img"
                        />
                      </div>
                      <p className="thanks-congrats-text pt-2">
                        <span>Congratulations  {userData.firstName}</span>
                      </p>
                      <div className="row details-section text-center">
                        <div className="col-12 p-0 px-md-3">
                          {/* <p className="thanks-user-name">
                            {userData.firstName} {userData.lastName} 
                          </p> */}
                          <p className="thanks-desc-notes mb-2">
                            You’ve successfully applied for our beta program.
                          </p>
                          <p className="thanks-desc-notes mb-2">
                            We’re looking forward to welcoming you to the Rentid family.
                          </p>
                          <p className="thanks-desc-notes mb-4">
                            Please be on the lookout for a confirmation email -- we’ll be in touch soon!
                          </p>

                          
                            <button
                              type="button"
                              className="btn btn-outline-dark returnbtn sharebtn radius-5"                          
                            >
                              <img
                          src="/img/beta-signup/shareicon.svg"
                          className="img-fluid px-1"
                          alt="feature img"
                        />  Share rentid with a friend
                            </button>
                        
                        </div>
                        <div className="col-12 p-0 px-md-3">
                          <a href="/">
                          <button
                            type="button"
                            className="btn btn-radius thanbtn"                           
                          >
                            Done!
                          </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default BetaThankyou;
