import React, { useState, useEffect, useCallback } from 'react';
import './RentAcademy.css'
import Model from '../../utils/model';

const RentAcademy = () => {

  const [hoveredItem, setHoveredItem] = useState(null);

  const showUpdateRow = useCallback((item) => () => setHoveredItem(item), []);
  const hideUpdateRow = useCallback(() => setHoveredItem(null), []);
  const [show, setShow] = useState(false);

  const [content, setContent] = useState({})

  const items = [
    {
      icon: "/img/rentacademy/resouce-center.png",
      header: "Resource Center",
      body: "Our comprehensive hub of guides, tutorials, and articles to help you maximize the benefits of our products and services.",
      mainImage: "Resource Center.png"
    },
    {
      icon: "/img/rentacademy/faq.png",
      header: "FAQ",
      body: "A quick reference guide for both hosts and guests with a curated collection of frequently asked questions and detailed answers to assist in resolving common inquiries and issues quickly.",
      mainImage: "FAQ.png"
    }
  ];


  const handleShow = (item) => {
    console.log("drfghbjn", item)
    setContent(item)
    setShow(true)
  }

  return (
    <div>
      <div className="row rentAcademy">
        <div className="col-xl-4 col-lg-4 left-side">
          <div className="row">
            <div className="col-12 px-0">
              <img className="logo" src="/img/rentAcdemy.svg" alt="Rentid Logo" />
              <h6 className="py-4 my-2">
                PLATFORM OF EDUCATION
              </h6>
            </div>
          </div>
          <h2 className="mb-0">
            Grow and learn on your time,
            <span className="text-bg">not your dime.</span>
          </h2>

          <p className="pt-lg-3 pt-4">
            Leverage our platform to educate yourself, inform your guests, and train your staff for healthier operations.
          </p>
        </div>

        <div className="col-xl-8 col-lg-8 col-md-8">
          <div className="row d-lg-block d-none">
            <div className="col-12 text-center px-0 position-relative">
              <div className="row">
                <div className="col-md-12">
                  <img src="/img/rentacademy/RENTACADEMY.png" alt="Animation Image" className="mainImage" />
                </div>

                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6 mx-auto py-5 subicons">
                      <div className="row">
                        <div className="col-md-4">
                          <div className={`box1 ${hoveredItem && hoveredItem !== 'box1' ? 'blur' : ''}`} onMouseEnter={showUpdateRow('box1')} onMouseLeave={hideUpdateRow}>
                            <img src="/img/rentacademy/FAQ.webp" alt="Animation Image" className={hoveredItem === 'box1' ? "icons" : ""} />
                            <div className={`hovertooltip ${hoveredItem === 'box1' ? 'show' : ''}`}>
                              <img src="/img/socialmedia.png" alt="Animation Image" />
                              <h5>FAQ</h5>
                              <p className='text-start'>
                                A quick reference guide for both host and guests with a curated collection of frequently asked questions and detailed answers to assist in resolving common inquiries and issues quickly.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                            <div className={`box2 ${hoveredItem && hoveredItem !== 'box2' ? 'blur' : ''}`} onMouseEnter={showUpdateRow('box2')} onMouseLeave={hideUpdateRow}>
                              <img src="/img/rentacademy/ResourceCenter.webp" alt="Animation Image" className={hoveredItem === 'box2' ? "mainImage" : ""} />
                              <div className={`hovertooltip ${hoveredItem === 'box2' ? 'show' : ''}`}>
                                <img src="/img/resource-center.png" alt="Animation Image" />
                                <h5>Resource Center</h5>
                                <p className='text-start'>
                                  Our comprehensive hub of guides, tutorials, and articles to help your maximize the benefits of our products and services.
                                </p>
                              </div>
                            </div>
                          </div>
                        <div className="col-md-4">
                          <div className={`box3 ${hoveredItem && hoveredItem !== 'box3' ? 'blur' : ''}`} onMouseEnter={showUpdateRow('box3')} onMouseLeave={hideUpdateRow}>
                            <img src="/img/rentacademy/podcast.webp" alt="Animation Image" className={hoveredItem === 'box3' ? "mainImage" : ""} />
                            <div className={`hovertooltip ${hoveredItem === 'box3' ? 'show' : ''}`}>
                              <img src="/img/rentacademy/Featured.png" alt="Animation Image" />
                              <h5>Podcast</h5>
                              <p className='text-start'>
                                missing page
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>


      </div>


      <div className="row mobileview d-lg-none d-md-none d-block">
        <div className="col-12">
          <img className="rentshieldlogo" src="/img/rentacademy/rentacademy-m.png" alt="Rentid Logo" />
        </div>
        <div className="col-12 pt-3">
          <div className="row">
            <div className="col-6">  <img className="paln" src="/img/rentacademy/ResourceCenter-m.png" alt="Rentid Logo" onClick={() => handleShow(items[0])} /></div>
            <div className="col-6">  <img className="paln" src="/img/rentacademy/faq-m.png" alt="Rentid Logo" onClick={() => handleShow(items[1])} /></div>
          </div>
        </div>
      </div>
      <Model prop={{ show, setShow, content }} style={{ zIndex: 999 }} />
    </div>
  )
}

export default RentAcademy
