import React from 'react';
import './Footer.css'
import { Link } from 'react-router-dom';

const Footer = ({activeKey,setActiveKey,isVisible, setIsVisible,handleCloseButtonClick,tabTitle,handleTabSelect}) => {

    const handleFooterMenuClick = (key) => {
        setActiveKey(key);
        setIsVisible(true);
        // alert(key)
      };

    return (
        <footer id="footer" className="footer position-relative">
            <div className="container">
                <div className="row align-items-top pb-4">
                    <div className="col-md-1">
                        <img src="/img/Outline-Rentid.svg" alt="" />
                    </div>
                    <div className="col-12 col-xs-12 col-md-2 footermenu px-lg-4 px-2">
                        <h2>Rentid</h2>
                        <ul>
                            <li><a href="#fleet">Fleets</a></li>
                            <li><a href="#partners">Partners</a></li>                           
                            <li><a href="#support">Support</a></li>
                        </ul>
                    </div>

                    <div className="col-12 col-xs-12 col-md-3 footermenu">
                        <h2>Products</h2>
                        {/* <ul>
                            <li><img src="/img/rentdirect.svg" alt="" /></li>
                            <li><img src="/img/rentshield.svg" alt="" /></li>
                            <li><img src="/img/rentid.svg" alt="" /></li>
                            <li><img src="/img/rentsuccess.svg" alt="" /></li>
                            <li><img src="/img/rentacademy.svg" alt="" /></li>
                        </ul>
                         */}
                    <ul>
                        {/* <li onClick={() => handleFooterMenuClick('home')}>Home</li> */}
                        <li onClick={() => handleFooterMenuClick('RentDirect')}><img src="/img/rentdirect.svg" alt="" /></li>
                        <li onClick={() => handleFooterMenuClick('Rentid')}><img src="/img/rentid.svg" alt="" /></li>
                        <li onClick={() => handleFooterMenuClick('rentshield')}><img src="/img/rentshield.svg" alt="" /></li>
                        <li onClick={() => handleFooterMenuClick('RentSuccess')}><img src="/img/rentsuccess.svg" alt="" /></li>
                        <li onClick={() => handleFooterMenuClick('RentAcademy')}><img src="/img/rentacademy.svg" alt="" /></li>
                    </ul>
                    </div>

                    <div className="col-12 col-xs-12 col-md-2 footermenu">
                        <h2>Features</h2>
                        <ul>
                            <li><a href="#office">Back Office</a></li>
                            <li><a href="#delivery">Delivery</a></li>
                            <li><a href="#marketing">Marketing</a></li>
                            <li><a href="#customizable">Customizable</a> </li>
                            <li><a href="#rentalapp">Rental App</a></li>
                        </ul>
                    </div>

                    <div className="col-12 col-xs-12 col-md-2 footermenu">
                        <h2>Insurance</h2>
                        <ul>
                            <li><a href="#insurance">Rental</a></li>
                            <li><a href="#insurance">Ride Share</a></li>
                        </ul>
                    </div>

                    <div className="col-12 col-xs-12 col-md-2 footermenu">
                        <h2>Sign Up</h2>
                        <ul>
                            <li><a href="#coverages">Waitlist</a></li>
                            <li><a href="/beta-signup">Beta</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row copyright">
                    <div className="col-md-12">
                        <div className="row">                           
                            <div className="col-md-5 text-center mx-auto">
                                <ul className="policy d-flex align-items-center justify-content-between">
                                    <li style={{ listStyle: 'none', display: 'flex' }}><img style={{paddingRight:'4px'}} src="/img/copyright.svg" alt="" width="20" /> 2024 Rentid, Inc.</li>
                                    <li><a href="terms" target='_blank'><u>Legal</u></a></li>
                                    <li><a href="privacy" target='_blank'><u>Privacy Policy</u></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-6 text-lg-end text-center">
                        <ul className="social-links d-flex justify-content-lg-end justify-content-center">
                            <li><img src="/img/facebook-01.svg" alt="" width="20" /></li>
                            <li><img src="/img/youtube.svg" alt="" width="20" /></li>
                            <li><img src="/img/instagram.svg" alt="" width="20" /></li>
                            <li><img src="/img/linkedin-01.svg" alt="" width="20" /></li>
                        </ul>
                    </div> */}
                </div>
            </div>
        </footer>
    );
};

export default Footer;
