import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./Component/Header/Header";
import Footer from "./Component/Footer/Footer";
import LandingPage from "./pages/LandingPage";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Betasignup from "./Component/BetaSignup/Betasignup";
import ThankYou from "./pages/ThankYou";
import BetaCongratulation from "./pages/BetaCongratulation";
import BetaThankyou from "./pages/BetaThankyou";
import { UserFormDataProvider } from "./context/FormDataContext";

function App() {
  return (
    <div className="App">
      <Routes>
        {/* <Route path='/' element={<Home/>} /> */}
        <Route path="/" element={
          <UserFormDataProvider>
            <LandingPage />
          </UserFormDataProvider>} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="terms" element={<Terms />} />
        <Route
          path="beta-signup"
          element={
            <UserFormDataProvider>
              <Betasignup />
            </UserFormDataProvider>
          }
        />
        <Route
          path="thankyou"
          element={
            <UserFormDataProvider>
              <ThankYou />
            </UserFormDataProvider>
          }
        />
        <Route path="congatulation" element={<BetaCongratulation />} />
        <Route path="betathankyou" element={  <UserFormDataProvider>
          <BetaThankyou />
          </UserFormDataProvider>} />
      </Routes>
    </div>
  );
}

export default App;
