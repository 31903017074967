// TelematicsDevices.js
import React, { useState, useEffect } from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';

const TelematicsDevices = ({ telematicsData, setTelematicsData }) => {
  const { setFieldValue, setFieldTouched, errors, touched, values } = useFormikContext();
  const [selectedTelematics, setSelectedTelematics] = useState(telematicsData || []);
  const [useTelematicsDevices, setUseTelematicsDevices] = useState(values.using_telematics === '1');
  const [isChecked, setIsChecked] = useState(values.telematics_option_detail === 'other');

  const telematicsOptions = [
    { value: '1', src: '/img/beta-signup/verizon.svg', alt: 'brand1' },
    { value: '2', src: '/img/beta-signup/zubie.svg', alt: 'brand2' },
    { value: '3', src: '/img/beta-signup/trax.svg', alt: 'brand3' },
    { value: '4', src: '/img/beta-signup/bouncie.svg', alt: 'brand4' }
  ];

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setSelectedTelematics((prev) => {
      const newSelection = prev.includes(value)
        ? prev.filter((v) => v !== value)
        : [...prev, value];
      setFieldValue('selected_telematics', newSelection);
      setTelematicsData(newSelection);
      return newSelection;
    });
  };

  useEffect(() => {
    if (!values.using_telematics) {
      setFieldValue('using_telematics', "0")
    }
  }, [values, setFieldValue]);

  const handleCheckboxOtherChange = (event) => {
    setIsChecked(event.target.checked);
    setFieldValue('telematics_option_detail', event.target.checked ? '' : '');
  };

  const toggleTelematicsDevices = () => {
    setUseTelematicsDevices(!useTelematicsDevices);
    setFieldValue('using_telematics', !useTelematicsDevices ? '1' : '0');
    if (!useTelematicsDevices) {
      setSelectedTelematics([]);
      setFieldValue('selected_telematics', []);
      setFieldValue('telematics_option_detail', '');
    }
  };

  useEffect(() => {
    if (useTelematicsDevices) {
      setSelectedTelematics([]);
      setFieldValue('selected_telematics', []);
      setFieldValue('telematics_option_detail', '');
    }
  }, [useTelematicsDevices, setFieldValue]);

  return (
    <div className="h-100">
      <div className="row h-100 align-items-center insurance-provid">
        <div className="col-12 col-xs-12 col-md-7 px-0 m-auto">
          <div className="general-info-form">
            <div className="row">
              <div className="col-12 p-0">
                <p className="llc-text">Do you use a telematics devices in your vehicle?</p>
                <p className="llc-desc mb-3">Select all brands that apply</p>
              </div>
            </div>
            <div className="row my-2">
              <ErrorMessage name="using_telematics" component="div" className="invalid-feedback" />
              <div className={`col-12 togglebtn ${useTelematicsDevices ? 'enabled' : ''}`}>
                I’m not using telematics devices
                <label className="switch">
                  <input
                    type="checkbox"
                    value={useTelematicsDevices ? '1' : '0'}
                    name="using_telematics"
                    checked={useTelematicsDevices}
                    onChange={toggleTelematicsDevices}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className={`row telematicsimg ${useTelematicsDevices ? 'disabled-glass' : ''}`}>
              {telematicsOptions.map((option, index) => (
                <div key={option.value} className={`col-12 col-xs-12 col-md-6 ${index % 2 !== 0 ? 'px-md-2' : ''} mt-3`}>
                  <div className="form-check ps-0">
                    <Field
                      type="checkbox"
                      id={`customCheck${option.value}`}
                      name="selected_telematics"
                      value={option.value}
                      className="form-check-input d-none"
                      onChange={handleCheckboxChange}
                      checked={selectedTelematics.includes(option.value)}
                      onBlur={() => setFieldTouched('selected_telematics', true)}
                      disabled={useTelematicsDevices}
                    />
                    <label
                      className={`form-check-field list-group-item w-100 ${selectedTelematics.includes(option.value) ? 'selected' : ''}`}
                      htmlFor={`customCheck${option.value}`}
                    >
                      <div className="row align-items-center justify-content-center">
                        <div className="col-12 text-center">
                          <img
                            src={option.src}
                            className="img-fluid brand-img"
                            alt={option.alt}
                          />
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              ))}
              {touched.selected_telematics && errors.selected_telematics ? (
                <div className="invalid-feedback">{errors.selected_telematics}</div>
              ) : null}
            </div>
            <div className={`row ${useTelematicsDevices ? 'disabled-glass' : ''}`}>
              <div className="col-12 col-xs-12 col-md-12 mt-3 px-md-2">
                <div className="form-check ps-0">
                  <label className={`form-check-label form-check-field w-100 ${isChecked ? 'checked-class' : ''}`} htmlFor="Other">
                    <div className="row">
                      <div className="col-12 p-0">
                        <div className="row">
                          <div className="col-10 ps-0">
                            <img src="/img/beta-signup/wifi.svg" className="img-fluid" alt="other" />
                          </div>
                          <div className="col-2 text-end">
                            <Field
                              type="checkbox"
                              id="Other"
                              name="telematics_option_detail"
                              value="other"
                              className="form-check-input checkbox-right"
                              onChange={handleCheckboxOtherChange}
                              checked={isChecked}
                            />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-12 ps-0">
                            <p className="llc-heading mb-0">Other</p>
                          </div>
                        </div>
                      </div>
                      {isChecked && (
                        <div className="col-12 mt-2 p-0">
                          <Field
                            type="text"
                            className="form-control"
                            id="whichOne"
                            name="telematics_option_detail"
                            placeholder="Which one?*"
                          />
                          <ErrorMessage name="telematics_option_detail" component="div" className="invalid-feedback" />
                        </div>
                      )}
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TelematicsDevices;
