import React from 'react'
import './GuestApp.css'
// import Slider from 'react-slick'


function GuestApp() {
    // var regularslider = {
    //     dots: true,
    //     infinite: true,
    //     speed: 1000,
    //     slidesToShow: 4,
    //     slidesToScroll: 4,
    //     autoplay: true,
    //     autoplaySpeed: 6000,
    //     responsive: [
    //         {
    //             breakpoint: 1024,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 infinite: true,
    //                 dots: true
    //             }
    //         },
    //         {
    //             breakpoint: 768,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 dots: true
    //             }
    //         },
    //         {
    //             breakpoint: 480,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 dots: true
    //             }
    //         }
    //     ]

    // }
    return (
        <>
            <section id="rentalapp">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-xl-8 col-lg-8 col-md-5 px-0">
                            <div className="row">
                                <div className="col-12 text-start px-1">
                                    <h6 className="pb-4">SEAMLESS GUEST APP </h6>
                                    <h2>
                                        Your brand just leveled up <br /> with a rental <span className="text-bg">app experience</span>
                                    </h2>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-5">
                            <p className="pt-lg-5 pt-2 mb-0">
                            Elevate your rental business with your brand’s<br/> exclusive page on the <b>Rentid App,</b> enhancing<br/> customer engagement and streamlining your services effortlessly.  
                            </p>
                        </div>
                    </div>

                    <div className="row pt-5">
                        <div className="col-md-5 mx-auto">
                                <video width="100%" autoPlay muted playsInline loop>
                                    <source src="/img/videos/Rentid-duo.mp4" type="video/mp4" />
                                </video>
                        </div>

                        {/* <div className="col-6 mx-auto d-flex align-items-center justify-content-center">
                            <img src="/img/mobile1.svg" className="w-100" alt="Slide 1"  data-bs-toggle="modal" data-bs-target="#mobile1" />
                            <img src="/img/mobile2.svg" className="w-100 mobile2" alt="Slide 1"  data-bs-toggle="modal" data-bs-target="#mobile2" />
                            
                        </div>                         */}
                    </div>

                    {/* <div className="modal fade" id="mobile1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-mobile1 modal-dialog-centered">
                            <div className="modal-content position-relative">
                            <button type="button" className="btn-close video-close-btn" data-bs-dismiss="modal" aria-label="Close">
                            </button>
                            <div className="modal-body p-0 rounded-5">
                                <video width="100%" controls autoPlay muted playsInline loop>
                                    <source src="/img/videos/app-experience1.mp4" type="video/mp4" />
                                </video>
                            </div>
                            </div>
                        </div>
                    </div> */}
{/* 
                    <div className="modal fade" id="mobile2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-mobile1 modal-dialog-centered">
                            <div className="modal-content position-relative">
                            <button type="button" className="btn-close video-close-btn" data-bs-dismiss="modal" aria-label="Close">
                            </button>
                            <div className="modal-body p-0 rounded-5">
                                <video width="100%" controls autoPlay muted playsInline loop>
                                    <source src="/img/videos/app-experience2.mp4" type="video/mp4" />
                                </video>
                            </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-md-12">

                            {/* <Slider {...regularslider}>
                                <div>
                                    <img src="/img/slider1.webp" className="w-100" alt="Slide 1" />
                                </div>
                                <div>
                                    <img src="/img/slider2.webp" className="w-100" alt="Slide 2" />
                                </div>
                                <div>
                                    <img src="/img/slider3.webp" className="w-100" alt="Slide 3" />
                                </div>
                                <div>
                                    <img src="/img/slider4.webp" className="w-100" alt="Slide 4" />
                                </div>
                                <div>
                                    <img src="/img/slider5.webp" className="w-100" alt="Slide 5" />
                                </div>
                                <div>
                                    <img src="/img/slider6.webp" className="w-100" alt="Slide 6" />
                                </div>
                                <div>
                                    <img src="/img/slider7.webp" className="w-100" alt="Slide 7" />
                                </div>
                                <div>
                                    <img src="/img/slider8.webp" className="w-100" alt="Slide 8" />
                                </div>
                                <div>
                                    <img src="/img/slider9.webp" className="w-100" alt="Slide 9" />
                                </div>
                                <div>
                                    <img src="/img/slider10.webp" className="w-100" alt="Slide 10" />
                                </div>
                                <div>
                                    <img src="/img/slider11.webp" className="w-100" alt="Slide 11" />
                                </div>
                                <div>
                                    <img src="/img/slider12.webp" className="w-100" alt="Slide 12" />
                                </div>
                                <div>
                                    <img src="/img/slider13.webp" className="w-100" alt="Slide 13" />
                                </div>
                                <div>
                                    <img src="/img/slider14.webp" className="w-100" alt="Slide 14" />
                                </div>
                                <div>
                                    <img src="/img/slider15.webp" className="w-100" alt="Slide 15" />
                                </div>
                                <div>
                                    <img src="/img/slider16.webp" className="w-100" alt="Slide 16" />
                                </div>
                            </Slider> */}

                        </div>
                        {/* <div className="row d-none">
                            <div className="regular slider mt-5">
                                <div>
                                    <img src="/img/slide1.svg" className="w-100" alt="Slide 1" />
                                </div>
                                <div>
                                    <img src="/img/slide2.svg" className="w-100" alt="Slide 2" />
                                </div>
                                <div>
                                    <img src="/img/slide3.svg" className="w-100" alt="Slide 3" />
                                </div>
                                <div>
                                    <img src="/img/slide4.svg" className="w-100" alt="Slide 4" />
                                </div>
                                <div>
                                    <img src="/img/slide5.svg" className="w-100" alt="Slide 5" />
                                </div>
                                <div>
                                    <img src="/img/slide6.svg" className="w-100" alt="Slide 6" />
                                </div>
                                <div>
                                    <img src="/img/slide7.svg" className="w-100" alt="Slide 7" />
                                </div>
                                <div>
                                    <img src="/img/slide8.svg" className="w-100" alt="Slide 8" />
                                </div>
                                <div>
                                    <img src="/img/slide9.svg" className="w-100" alt="Slide 9" />
                                </div>
                                <div>
                                    <img src="/img/slide10.svg" className="w-100" alt="Slide 10" />
                                </div>
                                <div>
                                    <img src="/img/slide11.svg" className="w-100" alt="Slide 11" />
                                </div>
                                <div>
                                    <img src="/img/slide12.svg" className="w-100" alt="Slide 12" />
                                </div>
                                <div>
                                    <img src="/img/slide13.svg" className="w-100" alt="Slide 13" />
                                </div>
                                <div>
                                    <img src="/img/slide14.svg" className="w-100" alt="Slide 14" />
                                </div>
                                <div>
                                    <img src="/img/slide15.svg" className="w-100" alt="Slide 15" />
                                </div>
                                <div>
                                    <img src="/img/slide16.svg" className="w-100" alt="Slide 16" />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default GuestApp