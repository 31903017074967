// BusinessState.js
import React from "react";
import { Field, ErrorMessage } from "formik";

const BusinessState = () => {
  return (
    <div className="h-100">
      <div className="row align-items-center h-100">
        <div className="col-12 col-xs-12 col-md-7 px-0 m-auto">
          <div className="business-state-form">
            <div className="row">
              <div className="col-12 p-0">
                <p className="llc-text">
                  Are you a registered legal business in your state?
                </p>
                <p className="llc-desc">
                  Do you use an LLC, corporation or similar to operate your business?
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-xs-12 col-md-6 p-0 pe-md-2">
                <div className="form-check ps-0">
                  <Field
                    type="radio"
                    className="form-check-input "
                    id="business_compliance"
                    name="business_compliance"
                    value="0"
                  />
                  <label
                    className="form-check-label form-check-field w-100"
                    htmlFor="business_compliance"
                  >
                    <div className="row">
                      <div className="col-10 ps-0">
                        <img
                          src="/img/beta-signup/llc.svg"
                          className="img-fluid"
                          alt="llc"
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-12 ps-0">
                        <p className="llc-heading mb-0">
                          LLC, Corp, or similar
                        </p>
                      </div>
                    </div>
                  </label>
                  <ErrorMessage
                    name="business_compliance"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="col-12 col-xs-12 col-md-6 mt-3 mt-md-0 p-0 ps-md-2">
                <div className="form-check ps-0">
                  <Field
                    type="radio"
                    className="form-check-input"
                    id="registeredNo"
                    name="business_compliance"
                    value="1"
                  />
                  <label
                    className="form-check-label form-check-field w-100"
                    htmlFor="registeredNo"
                  >
                    <div className="row">
                      <div className="col-10 ps-0">
                        <img
                          src="/img/beta-signup/elements.svg"
                          className="img-fluid"
                          alt="llc"
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-12 ps-0">
                        <p className="llc-heading mb-0">Self/Individual</p>
                      </div>
                    </div>
                  </label>
                  {/* <ErrorMessage
                    name="business_compliance"
                    component="div"
                    className="invalid-feedback"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessState;
