import React from "react";
import "./index.css";
const BetaCongratulation = () => {
  return (
    <div>
      <main className="beta-congratulation">
        <section>
          <div className="container text-center">
            <div className="row">
              <div className="col-12 text-center">
                <div className="img-section">
                  <img
                    src="/img/beta-signup/Featured.png"
                    className="img-fluid"
                    alt="feature img"
                  />
                </div>
                <div className="p-md-0 my-3">
                  <img
                    src="/img/rentid-logo.svg"
                    className="img-fluid"
                    alt="feature img"
                  />
                </div>
                <p className="congrats-text pt-2">
                  Congratulations [[Juan Lizcano]]
                </p>
                <p className="contratulation-text">You’ve successfully applied for our beta program.</p>
                <p className="contratulation-text">We’re looking forward to welcoming you to the Rentid family.</p>
                <p className="contratulation-text">Please be on the look out for a confirmation email -- we’ll be in touch soon!</p>
              </div>
            </div>
            <div className="row center-row align-items-center">
                <div className="col-12 text-md-center">
                  <button
                    type="button"
                    class="btn btn-radius"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Done!
                  </button>
                </div>
              </div>
          </div>
        </section>
        <footer class="footer fixed-bottom py-3 bg-light beta-footer">
          <div class="container">
            <div className="row">
              <div className="col-12 col-xs-12 col-md-6">
                <ul className="d-flex">
                  <li className="list-style-none">2024 Rentid, Inc.</li>
                  <li className="mx-4 mx-md-5">
                    <a href="#"> Terms</a>
                  </li>
                  <li>
                    <a href="#"> Privacy</a>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-xs-12 col-md-6">
                <ul className="d-flex justify-content-md-end">
                    <li className="list-style-none">English (US)</li>
                  <li className="mx-4 mx-md-5">
                    <a href="#"> Rp IDR</a>
                  </li>
                  <li>
                    <a href="#">SupportSupport</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </main>
    </div>
  );
};

export default BetaCongratulation;
