import React, { useState, useEffect, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import { Col, Row, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import './HeroSection.css';
import { API_ENDPOINT } from '../../Constant/Constant.js';

import 'aos/dist/aos.css';
import AOS from 'aos';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'   

import qs from 'qs';
import { Navigate, useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/FormDataContext.js';

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    // phone: Yup.string().required('Phone number is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    state: Yup.string().required('State is required'),
    fleet: Yup.string().required('Fleet size is required')
});


function HeroSection() {
    const navigate = useNavigate();
    const { updateUserFormData } = useContext(UserContext);
    const [isExpanded, setIsExpanded] = useState(false);
    const text = `By submitting this form, you consent to Rentid contacting you via SMS, phone, and email for information about our services, promotional offers, and updates. You understand and agree that Rentid may use automated technology including autodialing, AI generated messaging, and AI generated voice to contact you, and that consent is not a condition of purchase. Message frequency may vary, and message/data rates may apply. You can opt out at any time by following the instructions in our communications or by contacting us at contact@rentid.io.`;

    const shortText = text.slice(0, 120) + '...'; // First 160 characters + ellipsis
    const readMoreText = isExpanded ? text : shortText;

    const [value, setValue] = useState()

    const [show, setShow] = useState(false);
    const [initialData, setInitialData] = useState({ firstName: '', lastName: '' });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { register, handleSubmit, formState: { errors }, reset, control } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onInitialSubmit = (e) => {
        e.preventDefault();
        const firstName = e.target['waitlist[first_name]'].value;
        const lastName = e.target['waitlist[last_name]'].value;
        setInitialData({ firstName, lastName });
        handleShow();
    };

    const onSubmit = async (data) => {        
        try {
            // Prepare the form data object
            const formData = {
                'waitlist[first_name]': data.firstName || 'First Name',
                'waitlist[last_name]': data.lastName || 'Last Name',
                'waitlist[company_name]': data.companyName || 'Company Name',
                'waitlist[phone_number]': data.phone || 'Phone Number',
                'waitlist[email_address]': data.email || 'Email Address',
                'waitlist[state]': data.state || 'State',
                'waitlist[fleet_size]': data.fleet || 'Fleet Size',
            };
    
            // Convert formData object to URL-encoded string
            const urlEncodedData = qs.stringify(formData);
    
            // Configure the request
            let config = {
                method: 'post',
                // url: 'https://api.getrentid.io/api/waitlist/submit-waitlist',
                url: `${API_ENDPOINT}/waitlist/submit-waitlist`,
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                data: urlEncodedData
            };
    
            // Make the request
            axios.request(config)
                .then((response) => {
                    // console.log(JSON.stringify(response.data));
                   

                    updateUserFormData(data.firstName, data.lastName,data.companyName)                    // window.location.href = '/thankyou';
                    navigate("/beta-signup");
                })
                .catch((error) => {
                    console.error('Error submitting form:', error);
                });
    
            // Reset the form
            handleClose();
            reset();
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    

    return (
        <>
            <section id="hero" className="hero section">
                <video width="100%" autoPlay muted playsInline loop>
                    <source src="/img/bg-video.mp4" type="video/mp4" />
                </video>

                <div className="container banner-content px-0">
                    <div className="row align-items-center">
                        <div className="col-xl-8 col-lg-9 col-md-9 px-1">
                            <h2>
                                The only end-to-end vehicle
                                <br /> rental platform you’ll ever need.
                            </h2>
                            <p className="pt-lg-4 pt-3 pb-lg-5 mb-4">
                            {/* Join rental entrepreneurs who trust our 🤖 AI-assisted infrastructure to  <br />manage bookings with seamless vetting, embedded insurance, <br /> and automated workflows to increase profitability.  */}

                            Join rental entrepreneurs who trust our 🤖 AI-assisted infrastructure, featuring branded <br />
                             digital experiences, embedded insurance, trust and safety tools, rental back office, and  <br />resolution center. <span className='subtext'>Start for free and pay only when booked.</span>
                             
                            </p>
                            {/* <p className='pt-3 mb-lg-5 mb-4'>   <span>Take control today - Start for free and pay only when booked.</span></p> */}

                            <div className="row">
                                <div className="col-md-11 px-0">
                                    <div className="banner-form">
                                        <h3 className="mb-4">Join The Waitlist</h3>
                                        <form onSubmit={onInitialSubmit}>
                                            <div className="row align-items-center">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label htmlFor="first_name" className="form-label">
                                                            First Name
                                                        </label>
                                                        <input type="text" className="form-control" id="first_name"
                                                            name="waitlist[first_name]" aria-describedby="emailHelp" required />
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label htmlFor="last_name" className="form-label">
                                                            Last Name
                                                        </label>
                                                        <input type="text" className="form-control" id="last_name"
                                                            name="waitlist[last_name]" aria-describedby="emailHelp" required />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="mt-3">
                                                        <button type="submit" className="banner-btn">
                                                            <img src="/img/arrow-turn-backward.svg" className='me-2' alt="Arrow" />{" "}
                                                            Enter
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <p>
                                            Your information is safe with us. Read our <a href="privacy" target='_blank'>Privacy Policy.</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <Modal id="waitlist" className='joinlist' show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Join The Waitlist</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={handleSubmit(onSubmit)}>    
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="first_name">First Name</Form.Label>
                                                <Form.Control type="text" id="first_name" placeholder='John' name="waitlist[first_name]" {...register('firstName')}
                                                    isInvalid={!!errors.firstName} defaultValue={initialData.firstName} />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.firstName?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="last_name">Last Name</Form.Label>
                                                <Form.Control type="text" id="last_name" placeholder='Doe' name="lastName" {...register('lastName')}
                                                    isInvalid={!!errors.lastName} defaultValue={initialData.lastName} />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.lastName?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-12">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="company_name">Company name</Form.Label>
                                                <Form.Control type="text" id="company_name" placeholder='Optional' name="companyName" {...register('companyName')}
                                                    />
                                                {/* <Form.Control.Feedback type="invalid">
                                                    {errors.companyName?.message}
                                                </Form.Control.Feedback> */}
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="phone_number">Phone number</Form.Label>
                                                {/* <Form.Control type="text" id="phone_number" placeholder='(000) 000-000' name="phone" {...register('phone')}
                                                    isInvalid={!!errors.phone} />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.phone?.message}
                                                </Form.Control.Feedback> */}
                                                <Controller
                                                    name="phone"
                                                    control={control}
                                                    rules={{ required: 'Phone number is required' }} // Ensure phone number is required
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <PhoneInput
                                                            id="phone_number"
                                                            limitMaxLength="10"
                                                            defaultCountry="US"
                                                            placeholder="(000) 000-000"
                                                            value={value}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            ref={ref}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="email_address">Email address</Form.Label>
                                                <Form.Control type="email" id="email_address" placeholder='johndoe@company.com' name="email" {...register('email')}
                                                    isInvalid={!!errors.email} />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="state">State</Form.Label>
                                                <Form.Select id="state" name="state" {...register('state')} isInvalid={!!errors.state}>
                                                    <option value="">Select</option>
                                                    <option value='Alabama'>Alabama</option>
                                                    <option value='Alaska'>Alaska</option>
                                                    <option value='Arizona'>Arizona</option>
                                                    <option value='Arkansas'>Arkansas</option>
                                                    <option value='California'>California</option>
                                                    <option value='Colorado'>Colorado</option>
                                                    <option value='Connecticut'>Connecticut</option>
                                                    <option value='Delaware'>Delaware</option>
                                                    <option value='Florida'>Florida</option>
                                                    <option value='Georgia'>Georgia</option>
                                                    <option value='Hawaii'>Hawaii</option>
                                                    <option value='Idaho'>Idaho</option>
                                                    <option value='Illinois'>Illinois</option>
                                                    <option value='Indiana'>Indiana</option>
                                                    <option value='Iowa'>Iowa</option>
                                                    <option value='Kansas'>Kansas</option>
                                                    <option value='Kentucky'>Kentucky</option>
                                                    <option value='Louisiana'>Louisiana</option>
                                                    <option value='Maine'>Maine</option>
                                                    <option value='Maryland'>Maryland</option>
                                                    <option value='Massachusetts'>Massachusetts</option>
                                                    <option value='Michigan'>Michigan</option>
                                                    <option value='Minnesota'>Minnesota</option>
                                                    <option value='Mississippi'>Mississippi</option>
                                                    <option value='Missouri'>Missouri</option>
                                                    <option value='Montana'>Montana</option>
                                                    <option value='Nebraska'>Nebraska</option>
                                                    <option value='Nevada'>Nevada</option>
                                                    <option value='New Hampshire'>New Hampshire</option>
                                                    <option value='New Jersey'>New Jersey</option>
                                                    <option value='New Mexico'>New Mexico</option>
                                                    <option value='New York'>New York</option>
                                                    <option value='North Carolina'>North Carolina</option>
                                                    <option value='North Dakota'>North Dakota</option>
                                                    <option value='Ohio'>Ohio</option>
                                                    <option value='Oklahoma'>Oklahoma</option>
                                                    <option value='Oregon'>Oregon</option>
                                                    <option value='Pennsylvania'>Pennsylvania</option>
                                                    <option value='Rhode Island'>Rhode Island</option>
                                                    <option value='South Carolina'>South Carolina</option>
                                                    <option value='South Dakota'>South Dakota</option>
                                                    <option value='Tennessee'>Tennessee</option>
                                                    <option value='Texas'>Texas</option>
                                                    <option value='Utah'>Utah</option>
                                                    <option value='Vermont'>Vermont</option>
                                                    <option value='Virginia'>Virginia</option>
                                                    <option value='Washington'>Washington</option>
                                                    <option value='West Virginia'>West Virginia</option>
                                                    <option value='Wisconsin'>Wisconsin</option>
                                                    <option value='Wyoming'>Wyoming</option>
                                                   
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.state?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="fleet_size">Fleet size</Form.Label>
                                                <Form.Select id="fleet_size" name="fleet" {...register('fleet')} isInvalid={!!errors.fleet}>
                                                    <option value="">Select</option>
                                                    <option value="1">1</option>
                                                    <option value="2 to 5">2 to 5</option>
                                                    <option value="5 to 10">5 to 10</option>
                                                    <option value="Greater than 10">Greater than 10</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.fleet?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <p className='mb-0 disclaimer'>{readMoreText} <span onClick={() => setIsExpanded(!isExpanded)}>
                                            {isExpanded ? 'Read Less' : 'Read More'}
                                        </span></p>
                                        
                                        <p className="mt-3 mb-0 d-flex align-items-start">
                                            <input type="checkbox" id="privacy-checkbox" className="me-2"  required/>
                                            <label htmlFor="privacy-checkbox" className="me-2">
                                            I consent to receiving communications from Rentid via email, phone call, and SMS, including with the use of AI and automated dialing technologies, and I agree to Rentid’s <a href="terms" target="_blank" className="text-white">Terms of Service</a> and <a href="privacy" target="_blank" className="text-white">Privacy Policy</a>.
                                            </label>
                                           
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" className='joinbtn' variant="primary">
                                Join The Waitlist
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </section>
        </>
    )
}

export default HeroSection;
