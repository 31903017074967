import React, { useState } from 'react';
import './DigitalExperience.css';

function DigitalExperience() {
    const [videoSrc, setVideoSrc] = useState('/img/videos/default.mp4');

    const handleImageClick = (src) => {
        setVideoSrc(src);
    };

    return (
        <>
            <section id="customizable">
                <div className="container hoversection">
                    <div className="row align-items-end pt-lg-5 pt-0">
                        <div className="col-xl-9 col-lg-9 col-md-5 px-0">
                            <div className="row">
                                <div className="col-12 text-start py-lg-4 p-0 px-lg-2 px-0">
                                    <h6 className="pb-3">YOUR BRAND OUR TECH</h6>
                                    <h2 className="pt-2">Fully <span className="text-bg">branded digital experience</span></h2>
                                    <p className="subtitle pt-3">
                                        Bring your brand to life with customizable, branded <br />
                                        experiences for desktop, mobile, and app. It's all included.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-5 py-lg-5 py-0 px-0">
                            <p className="text-start mb-0 pt-2 px-0">
                                Custom Journeys That Are<br />
                                Simple To Use And Understand.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container hoverimages">
                    <div className="row align-items-end px-0">
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-6 mobile-app">
                                    <img
                                        src="/img/app-1.webp"
                                        alt="App"
                                        className="w-100 hover-animate"
                                        // onClick={() => handleImageClick('/img/videos/user_app.mp4')}
                                        // data-bs-toggle="modal"
                                        // data-bs-target="#videoModal"
                                    />
                                </div>
                                {/* <div className="col-md-6 web-app">
                                    <img
                                        src="/img/thankyou-1.webp"
                                        alt="Thank You"
                                        className="w-100 hover-animate"
                                        onClick={() => handleImageClick('/img/videos/mobileapp.mp4')}
                                        data-bs-toggle="modal"
                                        data-bs-target="#videoModal"
                                    />
                                </div> */}
                                <div className="col-md-6 web-app">
                                    <img 
                                    src="/img/thankyou-1.webp" alt="" 
                                    className="w-100 hover-animate" 
                                    // data-bs-toggle="modal" 
                                    // data-bs-target="#mobileModal" 
                                    />
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row align-items-end">
                                <div className="col-md-6 host-website">
                                    <img
                                        src="/img/guest2-11.webp"
                                        alt="Guest"
                                        className="w-100 hover-animate"
                                        // onClick={() => handleImageClick('/img/videos/website-mac.mp4')}
                                        // data-bs-toggle="modal"
                                        // data-bs-target="#videoModal"
                                    />
                                </div>
                                <div className="col-md-6 host-portal">
                                    <img
                                        src="/img/host-portal-1.webp"
                                        alt="Host Portal"
                                        className="w-100 hover-animate"
                                        // onClick={() => handleImageClick('/img/videos/ipad-video.mp4')}
                                        // data-bs-toggle="modal"
                                        // data-bs-target="#videoModal"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="modal fade" id="videoModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content position-relative">
                            <button type="button" className="btn-close video-close-btn" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div className="modal-body p-0 rounded-5">
                                <video key={videoSrc} width="100%" controls autoPlay muted playsInline loop>
                                    <source src={videoSrc} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="mobileModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-mobile modal-dialog-centered">
                        <div className="modal-content position-relative">
                        <button type="button" className="btn-close video-close-btn" data-bs-dismiss="modal" aria-label="Close">
                        </button>
                        <div className="modal-body p-0 rounded-5">
                            <video width="100%" controls autoPlay muted playsInline loop>
                                <source src="/img/videos/mobileapp.mp4" type="video/mp4" />
                            </video>
                        </div>
                        </div>
                    </div>
                </div> */}
            </section>

            <section id="seven-mobile" className="d-lg-none d-md-none d-block">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                        <img
                            src="/img/app-m.webp"
                            alt="App"
                            // onClick={() => handleImageClick('/img/videos/user_app.mp4')}
                            // data-bs-toggle="modal"
                            // data-bs-target="#videoModal"
                            style={{ width: '62%' }}
                        />
                            {/* <img src="/img/app-m.webp" alt="App" style={{ width: '62%' }} /> */}
                        </div>
                        <div className="col-12 text-start">
                            <div className="device-name">App</div>
                            <div className="title">
                                <h3>Title</h3>
                                <p>Description</p>
                                <h5>Boost Your Bookings</h5>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 text-center">
                            <img src="/img/mobile-m.webp"
                             alt="Mobile" 
                             style={{ width: '62%' }}  
                            //  data-bs-toggle="modal" 
                            //  data-bs-target="#mobileModal" 
                             />
                            
                        </div>
                        <div className="col-12 text-start">
                            <div className="device-name">Mobile</div>
                            <div className="title">
                                <h3>Title</h3>
                                <p>Description</p>
                                <h5>Boost Your Bookings</h5>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 text-center">
                            {/* <img src="/img/pad-m.webp" alt="Host Website" className="w-100" /> */}
                            <img
                                src="/img/pad-m.webp"
                                alt="Guest"
                                className="w-100"
                                // onClick={() => handleImageClick('/img/videos/website-mac.mp4')}
                                // data-bs-toggle="modal"
                                // data-bs-target="#videoModal"
                            />
                        </div>
                        <div className="col-12 text-start">
                            <div className="device-name">Host Website</div>
                            <div className="title">
                                <h3>Title</h3>
                                <p>Description</p>
                                <h5>Boost Your Bookings</h5>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 text-center px-0">
                            {/* <img src="/img/mac-m.webp" alt="MacBook Pro" className="w-100" /> */}
                            <img
                                src="/img/mac-m.webp"
                                alt="MacBook Pro"
                                className="w-100"
                                // onClick={() => handleImageClick('/img/videos/ipad-video.mp4')}
                                // data-bs-toggle="modal"
                                // data-bs-target="#videoModal"
                            />
                        </div>
                        <div className="col-12 text-start">
                            <div className="device-name">MacBook Pro</div>
                            <div className="title">
                                <h3>Title</h3>
                                <p>Description</p>
                                <h5>Boost Your Bookings</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DigitalExperience;
