import React, { useState, useEffect } from 'react';
import './Header.css';

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isScrolled = currentScrollPos > 0;
      setScrolled(isScrolled);

      if (prevScrollPos > currentScrollPos) {
        setShowHeader(true);
      } else {
        setShowHeader(false);
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  const additionalClass = scrolled ? 'scrolled' : '';
  const visibilityClass = showHeader ? 'visible' : 'hidden';

  return (
    <div className={`${additionalClass} ${visibilityClass}`}>
      <header id="header" className={`header d-flex align-items-center fixed-top`}>
        <div className="container position-relative d-flex align-items-center justify-content-between px-0">
          <div className="col-md-6 mainmenu d-flex align-items-center">
            <a href="/" className="logo d-flex align-items-center me-auto me-xl-0">
              <h1 className="sitename">
                <img src="/img/Rentid-white.svg" alt="desktop logo" className="desktop-logo" />
                <img src="/img/mobile-logo.svg" alt="mobile logo" className="mobile-logo" />
              </h1>
            </a>
          </div>

          <div className="col-md-6 rightbtn d-flex align-items-center justify-content-end">
            <a className="loginbtn d-lg-block d-none" target="_blank" href="https://getrentid.io/marketing/login">
              Login 
            </a>
            <a className="d-lg-none d-md-none d-block" target="_blank" href="https://getrentid.io/marketing/login">
              <img src="/img/login-btn.svg" alt="mobile logo" className="mobile-logo"  width={34}/>
            </a>
            <a className="btn-getstarted d-lg-block" href="/beta-signup">
              Apply For Beta
            </a>
          </div>

          <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
        </div>
      </header>


      
    </div>
  );
};

export default Header;
