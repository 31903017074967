import React, { useEffect, useRef, useState } from 'react';
import './HeroScroll.css'
import Slider from 'react-slick'

import 'aos/dist/aos.css';
import AOS from 'aos';


function HeroScroll() {

    const borderRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
              observer.unobserve(entry.target); // Stop observing after animation starts
            }
          });
        },
        { threshold: 0.1 } // Adjust as needed
      );
  
      if (borderRef.current) {
        observer.observe(borderRef.current);
      }
  
      return () => {
        if (borderRef.current) {
          observer.unobserve(borderRef.current);
        }
      };
    }, []);

    useEffect(() => {
        AOS.init({
          // once: true, // Uncomment if you want the animation to happen only once
          // duration: 1000, // Uncomment to set the duration of animations in milliseconds
          // other options can go here
        });
      }, []);
      

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };
    return (
        <>
            <section id="second">
                <div className="container">
                    <div className="row">
                        {/* Mobile slider start */}
                        <div className="col-md-12 d-lg-none d-md-none d-block">

                            <Slider {...settings}>

                                <div>
                                    <img src="/img/Autodealerships.png" alt="Image 1" />
                                </div>
                                <div>
                                    <img src="/img/marketplacehost.png" alt="Image 2" />
                                </div>
                                <div>
                                    <img src="/img/marketplacehostcopy.webp" alt="Image 3" />
                                </div>
                                <div>
                                    <img src="/img/Rentalagencies.webp" alt="Image 4" />
                                </div>
                                <div>
                                    <img src="/img/Serviceandrepair.webp" alt="Image 5" />
                                </div>
                                <div>
                                    <img src="/img/Rentalagencies2.webp" alt="Image 6" />
                                </div>

                            </Slider>

                        </div>
                        {/* Mobile slider End */}

                        <div className="col-xl-7 col-lg-6 col-md-9 left-side pb-5 my-lg-5 mb-0 px-0">
                            <div className="row align-items-center">
                                <div className="col-md-12 px-1">
                                    <h2 className="my-5 py-4">
                                        Discover why rental operators and
                                        <br /> entrepreneurs choose rentid to scale
                                        <br /> their business -
                                        <span className="text-bg">take control today.</span>
                                    </h2>
                                    <div className="row pt-3">
                                        <div className="col-md-5">
                                            <div ref={borderRef} className={`gredientLine ${isVisible ? 'visible' : ''}`}>
                                                <h3>Hundreds</h3>
                                                <p className="mb-4 pb-2">
                                                    of automated workflows,
                                                    <br />
                                                    one single platform
                                                </p>
                                            </div>
                                           
                                        </div>
                                        <div className="col-md-5">
                                            <div ref={borderRef} className={`gredientLine ${isVisible ? 'visible' : ''}`}>
                                                <h3>Four +</h3>
                                                <p className="mb-4 pb-2">
                                                    On-rent daily insurance  <br />
                                                    and protection plans
                                                </p>
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <div className="row pt-lg-5">
                                        <div className="col-md-5">
                                            <div ref={borderRef} className={`gredientLine ${isVisible ? 'visible' : ''}`}>
                                                <h3>10 seconds</h3>
                                                <p className="mb-4 pb-2">
                                                    or less to verify & qualify <br />
                                                    drivers and enhance safety
                                                </p>
                                            </div>
                                           
                                        </div>
                                        <div className="col-md-5">
                                            <div ref={borderRef} className={`gredientLine ${isVisible ? 'visible' : ''}`}>
                                                <h3>$57.5B</h3>
                                                <p className="mb-4 pb-2">
                                                    U.S. car rental market to
                                                    <br /> capture with our solutions
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 col-md-9">
                            <div className="overlaycolor"></div>
                            {/* <div className="row scroll-img">
                                <div className="col-lg-6 col-md-6 col-6 text-center position-relative">
                                    <div className="photobanner">
                                        <img className="first" src="/img/Autodealerships.png" alt="Image 1" />
                                        <img src="/img/marketplacehost.png" alt="Image 2" />
                                        <img src="/img/marketplacehostcopy.webp" alt="Image 3" />

                                        <img src="/img/Autodealerships.png" alt="Image 1" />
                                        <img src="/img/marketplacehost.png" alt="Image 2" />
                                        <img src="/img/marketplacehostcopy.webp" alt="Image 3" />

                                        <img src="/img/Autodealerships.png" alt="Image 1" />
                                        <img src="/img/marketplacehost.png" alt="Image 2" />
                                        <img src="/img/marketplacehostcopy.webp" alt="Image 3" />

                                        <img src="/img/Autodealerships.png" alt="Image 1" />
                                        <img src="/img/marketplacehost.png" alt="Image 2" />
                                        <img src="/img/marketplacehostcopy.webp" alt="Image 3" />

          
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-6 text-center">
                                    <div className="photobanner">
                                        <img className="first1" src="/img/Rentalagencies.webp" alt="Image 4" />
                                        <img src="/img/Serviceandrepair.webp" alt="Image 5" />
                                        <img src="/img/Rentalagencies2.webp" alt="Image 6" />

                                        <img src="/img/Rentalagencies.webp" alt="Image 4" />
                                        <img src="/img/Serviceandrepair.webp" alt="Image 5" />
                                        <img src="/img/Rentalagencies2.webp" alt="Image 6" />


                                        <img src="/img/Rentalagencies.webp" alt="Image 4" />
                                        <img src="/img/Serviceandrepair.webp" alt="Image 5" />
                                        <img src="/img/Rentalagencies2.webp" alt="Image 6" />


                                        <img src="/img/Rentalagencies.webp" alt="Image 4" />
                                        <img src="/img/Serviceandrepair.webp" alt="Image 5" />
                                        <img src="/img/Rentalagencies2.webp" alt="Image 6" />     

                                        
                                    </div>
                                </div>
                            </div> */}
                            <div className="row scroll-img">
                                <div className="col-md-6">
                                <div className="marquee">
                                    <div className="marquee-content">
                                    <img src="./img/Autodealerships.png" alt="Image 5"/>
                                    <img src="/img/marketplacehost.png" alt="Image 6"/>
                                    <img src="/img/marketplacehostcopy.webp" alt="Image 4"/>
                                    
                                    <img src="./img/Autodealerships.png" alt="Image 5"/>
                                    <img src="/img/marketplacehost.png" alt="Image 6"/>
                                    <img src="/img/marketplacehostcopy.webp" alt="Image 4"/>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div className="marquee">
                                    <div className="marquee-content1">
                                    <img src="/img/Rentalagencies.png" alt="Image 5"/>
                                    <img src="/img/Serviceandrepair.webp" alt="Image 6"/>
                                    <img src="/img/Rentalagencies2.webp" alt="Image 4"/>
                                    
                                    <img src="/img/Rentalagencies.png" alt="Image 4"/>
                                    <img src="/img/Serviceandrepair.webp" alt="Image 5"/>
                                    <img src="/img/Rentalagencies2.webp" alt="Image 6"/>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HeroScroll