import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import HeroSection from '../../Component/HeroSection/HeroSection';
import HeroScroll from '../../Component/HeroScroll/HeroScroll';
import AnimationSection from '../../Component/AnimationSection/AnimationSection';
import SupportTeam from '../../Component/SupportTeam/SupportTeam';
import Workflow from '../../Component/Workflow/Workflow';
import Communication from '../../Component/Communication/Communication';
import DigitalExperience from '../../Component/DigitalExperience/DigitalExperience';
import GuestApp from '../../Component/GuestAppSection/GuestApp';
import DriverlessVehicleDelivery from '../../Component/DriverlessVehicleDelivery/DriverlessVehicleDelivery';
import SolutionSection from '../../Component/SolutionSection/SolutionSection';
import Insurance from '../../Component/InsuranceSection/Insurance';
import StartFree from '../../Component/StartFreeSection/StartFree';
import Support from '../../Component/SupportSection/Support';
import Header from '../../Component/Header/Header';
import Footer from '../../Component/Footer/Footer';
const LandingPage = () => {
    const [isVisible, setIsVisible] = React.useState(false);
    const [activeKey, setActiveKey] = React.useState('RentDirect');
    const handleCloseButtonClick = () => {
        setActiveKey('home');
        setIsVisible(false);
    };
    // IntersectionObserver setup
    console.log("activeKeyactiveKeyactiveKey",activeKey)
    useEffect(() => {
        const fadeElements = document.querySelectorAll('.fade-element');

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('fade-in');
                    entry.target.classList.remove('fade-out');
                } else {
                    entry.target.classList.remove('fade-in');
                    entry.target.classList.add('fade-out');
                }
            });
        }, {
            threshold: 0.1
        });

        fadeElements.forEach(element => {
            observer.observe(element);
        });

        return () => {
            fadeElements.forEach(element => {
                observer.unobserve(element);
            });
        };
    }, []);
    const tabTitle = (image, active) => (
        <img src={active ? image.active : image.inactive} alt="" style={{ width: '58px', margin: '5px' }} />
    );
    const handleTabSelect = (key) => {
        setActiveKey(key);
        setIsVisible(key !== 'home');
    };

    return (
        <div>
            <Header/>
            <main className="main">
                {/* Hero Section */}
                <HeroSection />

                {/* Scroll image section */}
                <HeroScroll />


                {/* Animation section start */}
                <AnimationSection
                tabTitle={tabTitle}
                handleTabSelect={handleTabSelect}
                activeKey={activeKey} setActiveKey={setActiveKey} isVisible={isVisible} setIsVisible={setIsVisible} handleCloseButtonClick={handleCloseButtonClick}/>



                {/* Fully branded digital experience */}
                <DigitalExperience />

                {/* SEAMLESS GUEST APP */}
                <GuestApp />

                
                {/* YOUR DIGITAL SUPPORT TEAM */}
                <SupportTeam />

                {/* ADMINISTRATIVE TASKS DONE FOR YOU */}
                <Workflow />


                {/* MANAGE AND MARKET GUESTS WITH BUILT-IN TOOLS */}
                <Communication />





                {/* Driverless vehicle delivery */}
                <DriverlessVehicleDelivery />

                {/* Solution Section */}
                <SolutionSection />

                {/* Insurance section */}
                <Insurance />

                {/* start for free Section */}
                <StartFree />

                {/* Support Section */}
                <Support />

                {/* Footer Section */}
                {/* <Footer/> */}
                <Footer
                handleTabSelect={handleTabSelect}
                tabTitle={tabTitle}
                activeKey={activeKey} setActiveKey={setActiveKey} isVisible={isVisible} setIsVisible={setIsVisible} handleCloseButtonClick={handleCloseButtonClick}/>

            </main>
        </div>
    );
};

export default LandingPage;