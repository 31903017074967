import React, { useState, useEffect, useCallback } from 'react';
import './Rentid.css'
import Model from '../../utils/model';
const Rentid = () => {

  const [hoveredItem, setHoveredItem] = useState(null);

  const showUpdateRow = useCallback((item) => () => setHoveredItem(item), []);
  const hideUpdateRow = useCallback(() => setHoveredItem(null), []);
  const [show, setShow] = useState(false);

  const [content, setContent] = useState({})


  const items = [
    {
      icon: "/img/RentID/dnr.png",
      header: "DNR",
      body: "Our National Do Not Rent list (DNR) provides hosts with in-depth security measures against known bad actors.",
      mainImage: "rent-id-3.png"
    },
    {
      icon: "/img/RentID/insurance.png",
      header: "Personal Auto Insurance Validation",
      body: "Personal Auto Insurance Validation uses advanced verification techniques to ensure that renters have valid and adequate auto insurance coverage for your vehicle's coverage.",
      mainImage: "rent-id-4.png"
    },
    {
      icon: "/img/RentID/driver-iq.png",
      header: "DriverIQ™",
      body: "DriverIQ™ Score uses AI algorithms to provide you with a comprehensive assessment of a renter's reliability and risk level.",
      mainImage: "rent-id-5.png"
    }
  ];

  const handleShow = (item) => {
    console.log("drfghbjn", item)
    setContent(item)
    setShow(true)
  }

  return (
    <div>
      <div className="row rentid">
        <div className="col-xl-4 col-lg-4 left-side">
          <div className="row">
            <div className="col-12 px-0">
              <img className="logo" src="/img/rent-id-1.png" alt="Rentid Logo" />
              <h6 className="py-4 my-2">
                BESPOKE TRUST AND SAFETY TOOLS
              </h6>
            </div>
          </div>
          <h2 className="mb-0">
            Automated and<br/> built-in <span class="text-bg">security</span><br/>  <span class="text-bg">measures.</span>
          </h2>

          <p className="pt-lg-3 pt-4">
            Use data-driven decision-making to protect your account, vehicles, and future earnings. You alone control who gets the keys.
          </p>
        </div>

        <div className="col-xl-8 col-lg-8 col-md-8">
          <div className="row d-lg-block d-none">
            <div className="col-12 text-center px-0 position-relative">
              <div className="row">
                <div className="col-md-12">
                  <img src="/img/RentID/RENTID.png" alt="Animation Image" className="mainImage" />
                </div>

                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6 mx-auto py-5 subicons">
                      <div className="row">
                        <div className="col-md-4">
                          <div className={`box2 ${hoveredItem && hoveredItem !== 'box2' ? 'blur' : ''}`} onMouseEnter={showUpdateRow('box2')} onMouseLeave={hideUpdateRow}>
                            <img src="/img/RentID/Inspectvalidation.webp" alt="Animation Image" className={hoveredItem === 'box2' ? "mainImage" : ""} />
                            <div className={`hovertooltip ${hoveredItem === 'box2' ? 'show' : ''}`}>
                              <img src="/img/RentID/insurance.png" alt="Animation Image" />
                              <h5>Personal Auto Insurance Validation</h5>
                              <p className='text-start'>
                                Personal Auto Insurance Validation uses advanced verification techniques to ensure that renters have valid and adequate auto insurance coverage for your vehicle's coverage.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className={`box3 ${hoveredItem && hoveredItem !== 'box3' ? 'blur' : ''}`} onMouseEnter={showUpdateRow('box3')} onMouseLeave={hideUpdateRow}>
                            <img src="/img/RentID/DriverIQ.webp" alt="Animation Image" className={hoveredItem === 'box3' ? "mainImage" : ""} />
                            <div className={`hovertooltip ${hoveredItem === 'box3' ? 'show' : ''}`}>
                              <img src="/img/RentID/driver-iq.png" alt="Animation Image" width={20} />
                              <h5>DriverIQ™</h5>
                              <p className='text-start'>
                                DriverIQ™ Score useIDgorithms to provide you with a comprehensive assessment of a renter's reliability and risk level.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className={`box1 ${hoveredItem && hoveredItem !== 'box1' ? 'blur' : ''}`} onMouseEnter={showUpdateRow('box1')} onMouseLeave={hideUpdateRow}>
                            <img src="/img/RentID/DNR.webp" alt="Animation Image" className={hoveredItem === 'box1' ? "icons" : ""} />
                            <div className={`hovertooltip ${hoveredItem === 'box1' ? 'show' : ''}`}>
                              <img src="/img/RentID/dnr.png" alt="Animation Image" />
                              <h5>DNR</h5>
                              <p className='text-start'>
                                Our National Do Not rent list (DNR) provides hosts with in-depth security measure againist known bad actors.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>

      </div>

      <div className="row mobileview d-lg-none d-md-none d-block">

        <div className="col-12">
          <img className="mainimg" src="/img/RentID/rentacademy-m.png" alt="Rentid Logo" />
        </div>
        <div className="col-12 pt-3">
          <div className="row">
            <div className="col-6">  <img className="paln" src="/img/RentID/insurance-m.png" alt="Rentid Logo" onClick={() => handleShow(items[1])} /></div>
            <div className="col-6">  <img className="paln" src="/img/RentID/driveriq-m.png" alt="Rentid Logo" onClick={() => handleShow(items[2])} /></div>
          </div>
        </div>
        <div className="col-12 pt-3">
          <div className="row">
            <div className="col-6">  <img className="paln" src="/img/RentID/dnr-m.png" alt="Rentid Logo" onClick={() => handleShow(items[0])} /></div>
          </div>
        </div>

      </div>
      <Model prop={{ show, setShow, content }} style={{ zIndex: 999 }} />
    </div>
  )
}

export default Rentid