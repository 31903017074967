import React, { useState } from 'react'
import './Workflow.css'
import Slider from 'react-slick'

function Workflow() {
    const [activeTab, setActiveTab] = useState('tabone');

    const tabslider = {
        fade: true,
        draggable: true,
        cssEase: 'ease-in-out',
        touchThreshold: 100,
        dots: false,
        infinite: true,
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
    }

    return (
        <>
            <section id="fifth" className="">
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                            <p className="blackbox">
                                AUTOMATED
                                WORKFLOWS
                            </p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-12 px-1">
                            <div className="tabs">
                                <input type="radio" name="tabs" id="tabone" defaultChecked onChange={() => setActiveTab('tabone')} />
                                <label htmlFor="tabone">Administrative</label>
                                <div className={`tab ${activeTab === 'tabone' ? 'active' : ''}`}>
                                    <div className="row align-items-center">
                                        <div className="col-lg-4 col-md-4 px-0">
                                            <h6 className="mt-lg-0 mt-4 pt-lg-0 pt-2">ADMINISTRATIVE TASKS DONE FOR YOU</h6>
                                            <h2 className="mt-4">Renter violations</h2>
                                            <p className="pt-lg-2 pt-2 mb-2">
                                                Things can happen during the rental<br /> period that may require extra attention.
                                            </p>
                                            <p className="pt-lg-2 pt-2">
                                                Set fair compensation terms for<br />
                                                incidents, and we’ll manage your<br />
                                                documented submission with the renter<br />
                                                on your behalf.
                                            </p>
                                        </div>
                                        <div className="col-lg-8 col-md-8 position-relative">
                                        <svg class='line1' xmlns="http://www.w3.org/2000/svg" width="184" height="65" viewBox="0 0 184 65" fill="none">
                                            <path class="animated-line" d="M182 12.5V12.5C182 6.70101 177.299 2 171.5 2H22C10.9543 2 2 10.9543 2 22V65" stroke="#5E5CE6" stroke-width="3" stroke-linejoin="round"/>
                                        </svg>
                                            <img src="/img/adminstrative1.webp" alt="Administrative" className="zindex-img tabimg" />
                                            <svg class='line2' xmlns="http://www.w3.org/2000/svg" width="460" height="45" viewBox="0 0 534 45" fill="none">
                                                <path class="animated-line" d="M1.5 0.399414V22.9999C1.5 34.0456 10.4543 42.9999 21.5 42.9999H512C523.046 42.9999 532 34.0456 532 22.9999V2.99992" stroke="#28A5FF" stroke-width="3"/>
                                            </svg>
                                            
                                                {/* <img src="/img/violation-img.png" alt="Administrative" className="w-100" /> */}
                                        </div>
                                    </div>
                                </div>

                                <input type="radio" name="tabs" id="tabtwo" onChange={() => setActiveTab('tabtwo')} />
                                <label htmlFor="tabtwo" id="tabslider">Operational</label>
                                <div className={`tab ${activeTab === 'tabtwo' ? 'active' : ''}`}>
                                    <div className="row">
                                        <div className="col-md-12 position-relative px-0">
                                            <Slider {...tabslider} key={activeTab}>
                                                <div>
                                                    <div className="row align-items-center">
                                                        <div className="col-lg-5 col-md-5">
                                                            <h6 className="mt-lg-0 mt-4 pt-lg-0 pt-2">OPERATIONAL SERVICES DONE FOR YOU</h6>
                                                            <h2 className="mt-3">Automated Tolls<br/> and Tickets</h2>
                                                            <p className="pt-lg-3 pt-2">
                                                                Save time by letting us handle tolls and tickets<br /> during the
                                                                rental period.
                                                            </p>
                                                            <p className="pt-lg-3 pt-2">
                                                                Our tolls solution connects to all major tolling <br />systems
                                                                across the nation, ensuring
                                                                timely billing.
                                                            </p>
                                                            <p className="pt-lg-3 pt-2"> Tickets are transferred to the appropriate
                                                                party<br /> reducing
                                                                administrative burden and potential<br /> disputes.
                                                            </p>
                                                        </div>
                                                        <div className="col-lg-7 col-md-7 position-relative animated-images">
                                                        <svg class='line3' xmlns="http://www.w3.org/2000/svg" width="269" height="103" viewBox="0 0 269 103" fill="none">
                                                            <path class="animated-line" d="M1.19238 75.325V20.7546C1.19238 10.2764 9.68659 1.78223 20.1647 1.78223H248.307C258.786 1.78223 267.28 10.2764 267.28 20.7546V102.628" stroke="#5E5CE6" stroke-width="1.89724"/>
                                                        </svg>

                                                        <svg class='line4' xmlns="http://www.w3.org/2000/svg" width="41" height="195" viewBox="0 0 41 195" fill="none">
                                                            <path class="animated-line" d="M0.5 1.4375H20.3922C30.8703 1.4375 39.3646 9.93171 39.3646 20.4099V175.765C39.3646 185.527 31.4507 193.441 21.6885 193.441V193.441" stroke="#28A5FF" stroke-width="1.89724"/>
                                                        </svg>

                                                            {/* <img src="/img/ticket.png" alt="Operational" className="w-100" /> */}
                                                            <img src="/img/Operational1.webp" alt="Operational" className="pt-3 mt-5" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="row align-items-center">
                                                        <div className="col-lg-5 col-md-5">
                                                            <h6 className="mt-lg-0 mt-4 pt-lg-0 pt-2">OPERATIONAL SERVICES DONE FOR YOU</h6>
                                                            <h2 className="mt-3">On call Service and Maintenance </h2>
                                                            <p className="pt-lg-3 pt-2">
                                                                Less time on operations, more time with <br />customers.
                                                            </p>
                                                            <p className="pt-lg-3 pt-2">
                                                                We made pre and post trip services simple.<br /> With a single
                                                                tap, send professionals to
                                                                <br />service, maintain and wash your vehicle with <br />managed
                                                                operations.
                                                            </p>
                                                        </div>
                                                        <div className="col-lg-7 col-md-7 position-relative animated-images">                                                     
                                                        <svg class='line5' xmlns="http://www.w3.org/2000/svg" width="232" height="117" viewBox="0 0 232 117" fill="none">
                                                            <path class="animated-line" d="M230.159 60.2339L230.159 18.5845C230.159 8.87305 222.286 1.00033 212.575 1.00033L19.1487 1.00034C9.43718 1.00034 1.56445 8.87305 1.56445 18.5845L1.56445 116.177" stroke="#5E5CE6" stroke-width="1.75842"/>
                                                        </svg>
                                                            <img src="/img/services-img1.webp" alt="Service and Maintenance" className="pt-4" />
                                                            <svg class='line6'  xmlns="http://www.w3.org/2000/svg" width="125" height="56" viewBox="0 0 125 56" fill="none">
                                                                <path class="animated-line" d="M0.839844 1.28223H45.051C54.7625 1.28223 62.6352 9.15494 62.6352 18.8664V36.9754C62.6352 46.6869 70.5079 54.5596 80.2194 54.5596H124.431" stroke="#28A5FF" stroke-width="1.75842"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Slider>
                                        </div>
                                    </div>
                                </div>

                                <input type="radio" name="tabs" id="tabthree" onChange={() => setActiveTab('tabthree')} />
                                <label htmlFor="tabthree">Logistical</label>
                                <div className={`tab ${activeTab === 'tabthree' ? 'active' : ''}`}>
                                    <div className="row align-items-center">
                                        <div className="col-lg-5 col-md-5 px-0">
                                            <h6 className="mt-lg-0 mt-4 pt-lg-0 pt-2">LOGISTICAL SERVICES DONE FOR YOU</h6>
                                            <h2 className="mt-3">Vehicle Recovery<br/> and Roadside <br/>Assistance</h2>
                                            <p className="pt-lg-3 pt-2">
                                                Get vehicles back to safety and on the road<br/> earning revenue more efficiently.
                                            </p>
                                            <p className="pt-lg-3 pt-2">
                                                Our network of regional vehicle managers<br/> and local emergency services teams are<br/> ready to serve you
                                                at a moments notice.
                                            </p>
                                        </div>
                                        <div className="col-lg-7 col-md-7 position-relative animated-images">
                                        <svg class='line7' xmlns="http://www.w3.org/2000/svg" width="232" height="117" viewBox="0 0 232 117" fill="none">
                                             <path class="animated-line" d="M230.159 60.2339L230.159 18.5845C230.159 8.87305 222.286 1.00033 212.575 1.00033L19.1487 1.00034C9.43718 1.00034 1.56445 8.87305 1.56445 18.5845L1.56445 116.177" stroke="#5E5CE6" stroke-width="1.75842"/>
                                        </svg>
                                            <img src="/img/Logistical1.webp" alt="Logistical Services" className="tabimg pt-5 mt-5" />
                                            <svg class='line8'  xmlns="http://www.w3.org/2000/svg" width="125" height="56" viewBox="0 0 125 56" fill="none">
                                             <path class="animated-line" d="M0.839844 1.28223H45.051C54.7625 1.28223 62.6352 9.15494 62.6352 18.8664V36.9754C62.6352 46.6869 70.5079 54.5596 80.2194 54.5596H124.431" stroke="#28A5FF" stroke-width="1.75842"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Workflow
