import React, { useState, useEffect, useCallback } from "react";
import "./RentDirect.css";
import Model from "../../utils/model";

const RentDirect = () => {
const [hoveredItem, setHoveredItem] = useState(null);

const showUpdateRow = useCallback((item) => () => setHoveredItem(item), []);
const hideUpdateRow = useCallback(() => setHoveredItem(null), []);
const [show, setShow] = useState(false);

const [content, setContent] = useState({})

const items = [
{
icon: "/img/rentdirect/agreement.png",
header: "Agreements",
body: `Your pre-populated rental agreement instantly generates the moment a booking request is submitted.`,
},
{
icon: "/img/rentdirect/calender.png",
header: "Calendar Sync",
body: `Avoid duplicate bookings with automatic syncing of your RentDirect calendar from other booking platforms.`,
},
{
icon: "/img/rentdirect/crm.png",
header: "CRM",
body: `Track and nurture your contacts with integrated CRM database.`,
},
{
icon: "/img/rentdirect/modifytrip.png",
header: "Modify Trip",
body: `Two-way communication through your branded mobile app page ensure change requests are easy to submit and easy to
approve.`,
},
{
icon: "/img/rentdirect/notification.png",
header: "Notifications",
body: `Missing Text.`,
},
{
icon: "/img/rentdirect/violation.png",
header: "Violations",
body: `Make sure your policies are available and enforceable by building them right into the platform. We even help with
administration and recovery of any fees owed.`,
},
{
icon: "/img/rentdirect/guestapp.png",
header: "Guest App",
body: `Offer a seamless experience for guests with your own branded mobile app experience. They can manage bookings,
submit required documentation, and even lock/unlock vehicle for contactless handoffs.`,
},
{
icon: "/img/rentdirect/email-sms.png",
header: "Email/SMS",
body: `Meet your customers at their preferred communication touchpoints with email and text messaging built right in and
all managed from a single place.`,
},
{
icon: "/img/rentdirect/aichat.png",
header: "AI Chat",
body: `Put digital communication on autopilot with latest gen AI tools that handle conversations through email, text,
website, Facebook, Instagram, and your Google Business profile.`,
},
{
icon: "/img/rentdirect/payment.png",
header: "Payments",
body: `Safe and secure digital payments platform ready to accept major credit cards like Visa, Mastercard, American
Express, Discover, and Apple Pay, Google Pay, Amazon Pay, even ACH transfer.`,
},
{
icon: "/img/rentdirect/booking.png",
header: "Booking",
body: `Our booking engine integrates into any website to manage your vehicle availability and automate capture of all
required info from renters.`,
},
{
icon: "/img/rentdirect/telematics.png",
header: "Telematics",
body: `Get real-time views of your vehicle's use and location with your favorites providers integrated on the
platform.`,
},
{
icon: "/img/rentdirect/claim.png",
header: "Claims",
body: `Manage all insurance claim communications and documentation without leaving the platform.`,
},
{
icon: "/img/rentdirect/maintenance.png",
header: "Maintenance",
body: `Stay on top of maintenance requirements with scheduled reminders and a complete 360 view of every vehicle in your
fleet.`,
},
{
icon: "/img/rentdirect/extra.png",
header: "Add Extras",
body: `Offer more value to your customers and additional revenue streams by offering extras like car seats or prepaid
fuel.`,
},
{
icon: "/img/rentdirect/policies.png",
header: "Policies",
body: `Set your own customized policies with renters directly on your vehicle listing pages, automated messages, and
rental agreements.`,
},
];

const handleShow = (item) => {
setContent(item);
setShow(true);
};

return (
<div>
  <div className="row rentDirect">
    <div className="col-xl-4 col-lg-4 left-side">
      <div className="row">
        <div className="col-12 px-0">
          <img className="logo" src="/img/rentDirect.svg" alt="Rentid Logo" />
          <h6 className="py-4 my-2">FRICTIONLESS DIGITAL EXPEREINCES</h6>
        </div>
      </div>
      <h2 className="mb-0">
        Booking engine, fleet management and everything  <span className="text-bg">in between.</span>
      </h2>

      <p className="pt-lg-3 pt-4">
        The <b>rent</b>direct™ connected ecosystem handles the details so
        you can focus on on building relationships
      </p>
    </div>
    <div className="col-xl-8 col-lg-8 col-md-8">
      <div className="row d-lg-block d-none">
        <div className="col-12 text-center px-0 position-relative">
          <div className="row">
            <div className="col-md-12">
              <img src="/img/rentdirect/RENTDIRECT.png" alt="Animation Image" className="mainImage" />
            </div>

            <div className="col-md-12 imagehover">
              <div className="row">
                <div className="col-md-7 mx-auto py-4 subicons">
                  <div className="row gap-0 justify-content-center">
                    <div className="col-6 col-md-2">
                      <div className={`box1 no-padding ${hoveredItem && hoveredItem !=='box1' ? 'blur' : ''
                        }`} onMouseEnter={showUpdateRow("box1")} onMouseLeave={hideUpdateRow}>
                        <img src="/img/rentdirect/Agreements.webp" alt="Animation Image" className={hoveredItem==="box1"
                          ? "icons" : "" } />

                        <div className={`hovertooltip ${ hoveredItem==="box1" ? "show" : "" }`}>
                          <img src="/img/rentdirect/agreement.png" alt="Animation Image" />
                          <h5>Agreements</h5>
                          <p className="text-start">
                            Your pre-populated rental agreement instantly
                            generates the moment a booking request is
                            submitted.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-2">
                      <div className={`box2 no-padding ${hoveredItem && hoveredItem !=='box2' ? 'blur' : ''
                        }`} onMouseEnter={showUpdateRow("box2")} onMouseLeave={hideUpdateRow}>
                        <img src="/img/rentdirect/CalendarSync.webp" alt="Animation Image" className={
                          hoveredItem==="box2" ? "mainImage" : "" } />
                        <div className={`hovertooltip ${ hoveredItem==="box2" ? "show" : "" }`}>
                          <img src="/img/rentdirect/calender.png" alt="Animation Image" />
                          <h5>Calendar Sync</h5>
                          <p className="text-start">
                            Avoid duplicate bookings with automatic syncing of
                            your RentDirect calendar from other booking
                            platforms.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-2">
                      <div className={`box3 no-padding ${hoveredItem && hoveredItem !=='box3' ? 'blur' : ''
                        }`} onMouseEnter={showUpdateRow("box3")} onMouseLeave={hideUpdateRow}>
                        <img src="/img/rentdirect/CRM.webp" alt="Animation Image" className={ hoveredItem==="box3"
                          ? "mainImage" : "" } />
                        <div className={`hovertooltip ${ hoveredItem==="box3" ? "show" : "" }`}>
                          <img src="/img/rentdirect/crm.png" alt="Animation Image" />
                          <h5>CRM</h5>
                          <p className="text-start">
                            Track and nurture your contacts with integrated
                            CRM database.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-2">
                      <div className={`box4 no-padding ${hoveredItem && hoveredItem !=='box4' ? 'blur' : ''
                        }`} onMouseEnter={showUpdateRow("box4")} onMouseLeave={hideUpdateRow}>
                        <img src="/img/rentdirect/Modifytrip.webp" alt="Animation Image" className={hoveredItem==="box4"
                          ? "icons" : "" } />

                        <div className={`hovertooltip ${ hoveredItem==="box4" ? "show" : "" }`}>
                          <img src="/img/rentdirect/modifytrip.png" alt="Animation Image" />
                          <h5>Modify Trip</h5>
                          <p className="text-start">
                            Two-way communication through your branded mobile
                            app page ensure change requests are easy to submit
                            and easy to approve.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-2">
                      <div className={`box5 no-padding ${hoveredItem && hoveredItem !=='box5' ? 'blur' : ''
                        }`} onMouseEnter={showUpdateRow("box5")} onMouseLeave={hideUpdateRow}>
                        <img src="/img/rentdirect/Notifications.webp" alt="Animation Image" className={
                          hoveredItem==="box5" ? "mainImage" : "" } />
                        <div className={`hovertooltip ${ hoveredItem==="box5" ? "show" : "" }`}>
                          <img src="/img/rentdirect/notification.png" alt="Animation Image" />
                          <h5>Notifications</h5>
                          <p className="text-start">Missing Text.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-2">
                      <div className={`box6 no-padding ${hoveredItem && hoveredItem !=='box6' ? 'blur' : ''
                        }`} onMouseEnter={showUpdateRow("box6")} onMouseLeave={hideUpdateRow}>
                        <img src="/img/rentdirect/Violations.webp" alt="Animation Image" className={ hoveredItem==="box6"
                          ? "mainImage" : "" } />
                        <div className={`hovertooltip ${ hoveredItem==="box6" ? "show" : "" }`}>
                          <img src="/img/rentdirect/violation.png" alt="Animation Image" />
                          <h5>Violations</h5>
                          <p className="text-start">
                            Make sure your policies are available and
                            enforceable by building them right into the
                            platform. We even help with administration and
                            recovery of any fees owed.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-2">                 
                      <div className={`box7 no-padding ${hoveredItem && hoveredItem !=='box7' ? 'blur' : ''
                        }`} onMouseEnter={showUpdateRow("box7")} onMouseLeave={hideUpdateRow}>
                        <img src="/img/rentdirect/guestapp.webp" alt="Animation Image" className={hoveredItem==="box7"
                          ? "icons" : "" } />

                        <div className={`hovertooltip ${ hoveredItem==="box7" ? "show" : "" }`}>
                          <img src="/img/rentdirect/guestapp.png" alt="Animation Image" />
                          <h5>Guest App</h5>
                          <p className="text-start">
                            Offer a seamless experience for guests with your
                            own branded mobile app experience. They can manage
                            bookings, submit required documentation, and even
                            lock/unlock vehicle for contactless handoffs.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-2">
                      <div className={`box8 no-padding ${hoveredItem && hoveredItem !=='box8' ? 'blur' : ''
                        }`} onMouseEnter={showUpdateRow("box8")} onMouseLeave={hideUpdateRow}>
                        <img src="/img/rentdirect/EmailsSMS.webp" alt="Animation Image" className={ hoveredItem==="box8"
                          ? "mainImage" : "" } />
                        <div className={`hovertooltip ${ hoveredItem==="box8" ? "show" : "" }`}>
                          <img src="/img/rentdirect/email-sms.png" alt="Animation Image" />
                          <h5>Email/SMS</h5>
                          <p className="text-start">
                            Meet your customers at their preferred
                            communication touchpoints with email and text
                            messaging built right in and all managed from a
                            single place.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-2">
                      <div className={`col-6 col-md-2 box9 no-padding ${hoveredItem && hoveredItem !=='box9' ? 'blur' : ''
                        }`} onMouseEnter={showUpdateRow("box9")} onMouseLeave={hideUpdateRow}>
                        <img src="/img/rentdirect/AIChat.webp" alt="Animation Image" className={ hoveredItem==="box9"
                          ? "mainImage" : "" } />
                        <div className={`hovertooltip ${ hoveredItem==="box9" ? "show" : "" }`}>
                          <img src="/img/rentdirect/aichat.png" alt="Animation Image" />
                          <h5>AI Chat</h5>
                          <p className="text-start">
                            Put digital communication on autopilot with latest
                            gen AI tools that handle conversations through
                            email, text, website, Facebook, Instagram, and
                            your Google Business profile.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-2">
                      <div className={`col-6 col-md-2 box10 no-padding ${hoveredItem && hoveredItem !=='box10' ? 'blur'
                        : '' }`} onMouseEnter={showUpdateRow("box10")} onMouseLeave={hideUpdateRow}>
                        <img src="/img/rentdirect/Payments.webp" alt="Animation Image" className={hoveredItem==="box10"
                          ? "icons" : "" } />

                        <div className={`hovertooltip ${ hoveredItem==="box10" ? "show" : "" }`}>
                          <img src="/img/rentdirect/payment.png" alt="Animation Image" />
                          <h5>Payments</h5>
                          <p className="text-start">
                            Safe and secure digital payments platform ready to
                            accept major credit cards like Visa, Mastercard,
                            American Express, Discover, and Apple Pay, Google
                            Pay, Amazon Pay, even ACH transfer.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-2">
                      <div className={`col-6 col-md-2 box11 no-padding ${hoveredItem && hoveredItem !=='box11' ? 'blur'
                        : '' }`} onMouseEnter={showUpdateRow("box11")} onMouseLeave={hideUpdateRow}>
                        <img src="/img/rentdirect/booking.webp" alt="Animation Image" className={ hoveredItem==="box11"
                          ? "mainImage" : "" } />
                        <div className={`hovertooltip ${ hoveredItem==="box11" ? "show" : "" }`}>
                          <img src="/img/rentdirect/booking.png" alt="Animation Image" />
                          <h5>Booking</h5>
                          <p className="text-start">
                            Our booking engine integrates into any website to
                            manage your vehicle availability and automate
                            capture of all required info from renters.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-2">
                      <div className={`box12 no-padding ${hoveredItem && hoveredItem !=='box12' ? 'blur'
                        : '' }`} onMouseEnter={showUpdateRow("box12")} onMouseLeave={hideUpdateRow}>
                        <img src="/img/rentdirect/Claims.webp" alt="Animation Image" className={ hoveredItem==="box12"
                          ? "mainImage" : "" } />
                        <div className={`hovertooltip ${ hoveredItem==="box12" ? "show" : "" }`}>
                          <img src="/img/rentdirect/telematics.png" alt="Animation Image" />
                          <h5>Telematics</h5>
                          <p className="text-start">
                            Get real-time views of your vehicle's use and
                            location with your favorites providers integrated
                            on the platform.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-2">
                      <div className={`box13 no-padding ${hoveredItem && hoveredItem !=='box13' ? 'blur'
                        : '' }`} onMouseEnter={showUpdateRow("box13")} onMouseLeave={hideUpdateRow}>
                        <img src="/img/rentdirect/Claims-1.webp" alt="Animation Image" className={hoveredItem==="box13"
                          ? "icons" : "" } />

                        <div className={`hovertooltip ${ hoveredItem==="box13" ? "show" : "" }`}>
                          <img src="/img/rentdirect/claim.png" alt="Animation Image" />
                          <h5>Claims</h5>
                          <p className="text-start">
                            Manage all insurance claim communications and
                            documentation without leaving the platform.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-2">
                      <div className={`box14 no-padding ${hoveredItem && hoveredItem !=='box14' ? 'blur'
                        : '' }`} onMouseEnter={showUpdateRow("box14")} onMouseLeave={hideUpdateRow}>
                        <img src="/img/rentdirect/Maintainance.webp" alt="Animation Image" className={
                          hoveredItem==="box14" ? "mainImage" : "" } />
                        <div className={`hovertooltip ${ hoveredItem==="box14" ? "show" : "" }`}>
                          <img src="/img/rentdirect/maintenance.png" alt="Animation Image" />
                          <h5>Maintenance</h5>
                          <p className="text-start">
                            Stay on top of maintenance requirements with
                            scheduled reminders and a complete 360 view of
                            every vehicle in your fleet.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-2">
                      <div className={`box15 no-padding ${hoveredItem && hoveredItem !=='box15' ? 'blur'
                        : '' }`} onMouseEnter={showUpdateRow("box15")} onMouseLeave={hideUpdateRow}>
                        <img src="/img/rentdirect/Addextras.webp" alt="Animation Image" className={ hoveredItem==="box15"
                          ? "mainImage" : "" } />
                        <div className={`hovertooltip ${ hoveredItem==="box15" ? "show" : "" }`}>
                          <img src="/img/rentdirect/extra.png" alt="Animation Image" />
                          <h5>Add Extras</h5>
                          <p className="text-start">
                            Offer more value to your customers and additional
                            revenue streams by offering extras like car seats
                            or prepaid fuel.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-2">
                      <div className={`box16 no-padding ${hoveredItem && hoveredItem !=='box16' ? 'blur'
                        : '' }`} onMouseEnter={showUpdateRow("box16")} onMouseLeave={hideUpdateRow}>
                        <img src="/img/rentdirect/Maintainance-1.webp" alt="Animation Image"
                          className={hoveredItem==="box16" ? "icons" : "" } />

                        <div className={`hovertooltip ${ hoveredItem==="box16" ? "show" : "" }`}>
                          <img src="/img/rentdirect/policies.png" alt="Animation Image" />
                          <h5>Policies</h5>
                          <p className="text-start">
                            Set your own customized polices with renters
                            directly on your vehicle listing pages, automated
                            messages, and rental agreements.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="row mobileview d-lg-none d-md-none d-block px-0">
      <div className="col-md-12 px-0">
        <div className="row">
          <div className="col-md-6 mx-auto subicons px-0">
            <div className="row gap-0 justify-content-center">
              <div className="col-12 main-image">
                <img src="/img/rentdirect/rentdirect-m.png" alt="Animation Image" />
              </div>

              <div className="col-12 pt-3 directimages px-0">
                <div className="row">
                  <div className="col-6">
                    <img src="/img/rentdirect/agreement-m.png" alt="Animation Image" onClick={()=> handleShow(items[0])}
                    />
                  </div>
                  <div className="col-6">
                    <img src="/img/rentdirect/calender-m.png" alt="Animation Image" onClick={()=> handleShow(items[1])}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 pt-3 directimages px-0">
                <div className="row">
                  <div className="col-6">
                    <img src="/img/rentdirect/crm-m.png" alt="Animation Image" onClick={()=> handleShow(items[2])}
                    />
                  </div>
                  <div className="col-6">
                    <img src="/img/rentdirect/modifytrip-m.png" alt="Animation Image" onClick={()=> handleShow(items[3])}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 pt-3 directimages px-0">
                <div className="row">
                  <div className="col-6">
                    <img src="/img/rentdirect/notification-m.png" alt="Animation Image" onClick={()=> handleShow(items[4])}
                    />
                  </div>
                  <div className="col-6">
                    <img src="/img/rentdirect/violation-m.png" alt="Animation Image" onClick={()=> handleShow(items[5])}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 pt-3 directimages px-0">
                <div className="row">
                  <div className="col-6">
                    <img src="/img/rentdirect/guestapp-m.png" alt="Animation Image" onClick={()=> handleShow(items[6])}
                    />
                  </div>
                  <div className="col-6">
                    <img src="/img/rentdirect/email-sms-m.png" alt="Animation Image" onClick={()=> handleShow(items[7])}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 pt-3 directimages px-0">
                <div className="row">
                  <div className="col-6">
                    <img src="/img/rentdirect/aichat-m.png" alt="Animation Image" onClick={()=> handleShow(items[8])}
                    />
                  </div>
                  <div className="col-6">
                    <img src="/img/rentdirect/payment-m.png" alt="Animation Image" onClick={()=> handleShow(items[9])}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 pt-3 directimages px-0">
                <div className="row">
                  <div className="col-6">
                    <img src="/img/rentdirect/booking-m.png" alt="Animation Image" onClick={()=> handleShow(items[10])}
                    />
                  </div>
                  <div className="col-6">
                    <img src="/img/rentdirect/telematics-m.png" alt="Animation Image" onClick={()=> handleShow(items[11])}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 pt-3 directimages px-0">
                <div className="row">
                  <div className="col-6">
                    <img src="/img/rentdirect/claim-m.png" alt="Animation Image" onClick={()=> handleShow(items[12])}
                    />
                  </div>
                  <div className="col-6">
                    <img src="/img/rentdirect/maintenance-m.png" alt="Animation Image" onClick={()=> handleShow(items[13])}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 py-3 directimages px-0">
                <div className="row">
                  <div className="col-6">
                    <img src="/img/rentdirect/extra-m.png" alt="Animation Image" onClick={()=> handleShow(items[14])}
                    />
                  </div>
                  <div className="col-6">
                    <img src="/img/rentdirect/policies-m.png" alt="Animation Image" onClick={()=> handleShow(items[15])}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Model prop={{ show, setShow, content }} style={{ zIndex: 999 }} />
  </div>
</div>
);
};

export default RentDirect;