import React, { useEffect } from 'react'
import './Communication.css'

function Communication() {

  useEffect(() => {
    const stickyElement = document.querySelector('.sticky-lg-top');

    if (stickyElement) {
      const disableScroll = (e) => {
        e.preventDefault();
      };

      stickyElement.addEventListener('wheel', disableScroll, { passive: false });

      // Clean up the event listener
      return () => {
        stickyElement.removeEventListener('wheel', disableScroll);
      };
    }
  }, []);


    return (
        <>
    <section id="marketing" className="">
      <div className="container">
        {/* Manage and Market Guests */}
        <div className="row align-items-center sticky-lg-top " >
          <div className="col-xl-8 col-lg-8 col-md-5 px-0">
            <div className="row">
              <div className="col-12 text-start px-1">
                <h6 className="my-4">MANAGE AND MARKET GUESTS WITH BUILT-IN TOOLS </h6>
                <h2 className="my-2">From first impressions to <span className="text-bg">lasting</span><br /> <span className="text-bg p-0">relationships</span>, your CRM does it
                  all</h2>
                <p className="sub-title pt-4">rentAI 🤖 - Effortless Efficiency, Maximum
                  Impact. </p>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 col-md-5 px-0">
            <p className="m-0 p-lg-0 py-2">Connect and sync with renters seamlessly<br />
              across their preferred touch points with<br /> automated workflows
              powered by rentAI 🤖.
            </p>
          </div>
        </div>

        <div className="row align-items-center reverse-columns py-5 fade-element">
          <div className="col-lg-5 col-md-5">
            <div className="row pt-lg-0 pt-5">
              <div className="col-md-2 col-2">
                <img src="/img/communication-icon.svg" alt="" width="48" />
              </div>
              <div className="col-md-9 col-10">
                <h3 className="mb-3 pt-0">Centralized  Communications <br/>& Automated Messaging</h3>
                  <p className='subtitle'>
                    All messages in one communications hub</p>
                  <p>Integrate email, SMS, phone, and direct <br />
                  messages from Facebook, Instagram, and <br />
                    Google Business Profile into one  efficient <br />
                     system. Track every interaction for consistent <br />
                    communication across all   channels.</p>
                  <p>Enhance efficiency with automated follow- <br />
                    ups, reminders, and communications for <br /> 
                    booking confirmations, check-in/out <br />
                    instructions, and promotional offers.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-7">
            <img src="/img/communication.webp" alt="" className="w-100" />
          </div>
        </div>

        {/* Integrated CRM & Booking Engine */}
        <div className="row align-items-center reverse-columns py-5 fade-element">
          <div className="col-lg-5 col-md-5">
            <div className="row pt-lg-0 pt-5">
              <div className="col-md-2 col-2">
                <img src="/img/calendar-icon.svg" alt="" width="48" />
              </div>
              <div className="col-md-9 col-10">
                <h3 className="mb-3 pt-0">Integrated CRM & Booking <br />Engine </h3>

                <p>You’re in control of customer relationships <br /><br /></p>
                
                <p>  Effortlessly track bookings, rental histories, <br />
                   and customer preferences to deliver <br />
                    personalized and efficient service. <br /><br /></p>
                    <p>Your customer data automatically syncs with <br />
                     our CRM, enabling you to leverage advanced <br />
                      marketing automation tools to engage the <br />
                       right clients precisely when it matters most.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-7">
            <img src="/img/crm.webp" alt="" className="w-100" />
          </div>
        </div>

        {/* Advanced Analytics & Comprehensive Reporting */}
        <div className="row align-items-center reverse-columns py-4 fade-element">
          <div className="col-lg-5 col-md-5">
            <div className="row pt-lg-0 pt-5">
              <div className="col-md-2 col-2">
                <img src="/img/waterfall-graph.svg" alt="" width="48" />
              </div>
              <div className="col-md-9 col-10">
                <h3 className="mb-3 pt-0">Advanced Analytics & <br /> Comprehensive
                  Reporting </h3>
                  <p className='subtitle'>Make real-time data-driven decisions. </p>

                  <p>Unlock valuable business insights with <br /> advanced analytics and reporting. </p>

                  <p>Monitor key performance indicators, track <br />
                   customer trends to enhance your operations <br />
                    and marketing strategies.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-7">
            <img src="/img/analytics.webp" alt="" className="w-100" />
          </div>
        </div>

      </div>
    </section>
        </>
    )
}

export default Communication