import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CompOne from '../CompOne/CompOne';
import RentShield from '../RentShield/RentShield';
import RentDirect from '../RentDirect/RentDirect';
import Rentid from '../Rentid/Rentid';
import RentSuccess from '../RentSuccess/RentSuccess';
import RentAcademy from '../RentAcademy/RentAcademy';
import './AnimationSection.css'


function AnimationSection({ activeKey, setActiveKey, isVisible, setIsVisible, handleCloseButtonClick, tabTitle, handleTabSelect }) {

    const [hoveredTab, setHoveredTab] = useState(null);


    const handleClick = (event) => {
        setActiveKey(event.target.id);
        setIsVisible(true);
    };

    const handleFooterMenuClick = (key) => {
        setActiveKey(key);
        setIsVisible(true);
        // alert(key)
    };


    return (
        <>
            <section id="third">
                {/* <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-5 col-lg-5 left-side px-1">
                            <div className="row">
                                <div className="col-12 px-0">
                                    <img className="logo1" src="/img/rentid-logo.svg" alt="Rentid Logo" />
                                    <h6 className="py-4 my-2">
                                        AUTOMATED END-TO-END RENTAL PLATFORM
                                    </h6>
                                </div>
                            </div>
                            <h2 className="mb-0">
                                Seamless, effortless,
                                <br /> and commissionless &nbsp;
                                <br />
                                <span className="text-bg p-0">rental platform.</span>
                            </h2>

                            <p className="pt-lg-3 pt-4 mt-3">
                                Rent vehicles to customers with our suite of
                                <br /> connected products and tools for an all-in-
                                <br />
                                one modern experience. From on-board to
                                on-<br />rent in
                                <span className="text-bg">less than 60 minutes.</span>
                            </p>
                            <div className="outlinebtn pt-3">
                                <button type="button" className="btn bg-transparent text-dark px-0" id='RentDirect' onClick={handleClick}>
                                    Learn more <i className="bi bi-arrow-right"></i>
                                </button>
                            </div>

                           
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-7">
                            <div className="row d-lg-block d-none">
                                <div className="col-12 text-lg-end text-center py-5 px-0 position-relative">
                                    <img src="/img/animation-new1.svg" alt="Animation Image" id='RentDirect' className="animationImg animationmain" onClick={handleClick} />


                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="container">
                    <div className="row">
                        <div className="col-12 text-lg-center text-start px-0">
                            <img className="logo11" src="/img/rentid-logo.svg" alt="Rentid Logo" width={117} />
                            <h2 className="pt-4 mt-2">
                                Seamless, effortless, and commissionless rental platform.
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-11 mx-auto">
                            <div className="row mobielhovernone">
                                <div className="col">
                                    <div className="imgwidth rentidirect" onClick={() => handleFooterMenuClick('RentDirect')}></div>
                                </div>
                                <div className="col">
                                    <div className="imgwidth rentids" onClick={() => handleFooterMenuClick('Rentid')}></div>
                                </div>
                                <div className="col">
                                    <div className="imgwidth rentshields" onClick={() => handleFooterMenuClick('rentshield')}></div>
                                </div>
                                <div className="col">
                                    <div className="imgwidth rentsuccess" onClick={() => handleFooterMenuClick('RentSuccess')}></div>
                                </div>
                                <div className="col">
                                    <div className="imgwidth rentacademy" onClick={() => handleFooterMenuClick('RentAcademy')}></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="row mobielhovernone">
                        <div className="col position-relative">
                            <div className="logo-container" onClick={() => handleFooterMenuClick('RentDirect')}>
                                <img className="logo active" src="/img/animation/rentdirect-active.png" alt="Animation Image" />
                                <img className="logo hover" src="/img/animation/rentdirect-hover.webp" alt="Rentdirect Logo" />
                            </div>
                        </div>
                        <div className="col position-relative">
                            <div className="logo-container" onClick={() => handleFooterMenuClick('Rentid')}>
                                <img className="logo active" src="/img/animation/rentid-active.png" alt="Rentdirect Logo" />
                                <img className="logo hover" src="/img/animation/rentID-hover.webp" alt="Rentdirect Logo" />
                            </div>
                        </div>
                        <div className="col position-relative">
                            <div className="logo-container" onClick={() => handleFooterMenuClick('rentshield')}>
                                <img className="logo active" src="/img/animation/rentshield-active.png" alt="Rentdirect Logo" />
                                <img className="logo hover" src="/img/animation/rentshield-hover.webp" alt="Rentdirect Logo" />
                            </div>
                        </div>
                        <div className="col position-relative">
                            <div className="logo-container" onClick={() => handleFooterMenuClick('RentSuccess')}>
                                <img className="logo active" src="/img/animation/rentsuccess-active.png" alt="Rentdirect Logo" />
                                <img className="logo hover" src="/img/animation/rentsuccess-hover.webp" alt="Rentdirect Logo" />
                            </div>
                        </div>
                        <div className="col position-relative">
                            <div className="logo-container" onClick={() => handleFooterMenuClick('RentAcademy')}>
                                <img className="logo active" src="/img/animation/rent-academy-active.png" alt="Rentdirect Logo" />
                                <img className="logo hover" src="/img/animation/rent-academ-hover.webp" alt="Rentdirect Logo" />
                            </div>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-12 text-lg-center text-start px-0">
                            <p>
                                Our suite of connected products and tools work together to bring you  <br />the most advanced all-in-one rental platform in the world.
                            </p>
                            <h2 class="pt-2 heading animation-subtitle"><span class="text-bg">From on-board to on-rent in less  than 60 minutes.</span></h2>
                        </div>
                    </div>

                    <div className="row d-lg-none d-block pt-4">
                        <div className="col-12 px-3">
                            <img src="/img/rentdirect-animation.svg" id='RentDirect' alt="Rentdirect Animation" className="w-100" onClick={handleClick} />
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-6 pb-3 subicons">
                                    <img src="/img/rentacademy-animation.svg" id='RentAcademy' alt="Rentacademy Animation" className="w-100" onClick={handleClick} />
                                </div>
                                <div className="col-6 pb-3 subicons">
                                    <img src="/img/rentsuccess-animation.svg" id='RentSuccess' alt="Rentsuccess Animation" className="w-100" onClick={handleClick} />
                                </div>
                                <div className="col-6 pb-3 subicons">
                                    <img src="/img/rentid-animation.svg" id='Rentid' alt="Rentid Animation" className="w-100" onClick={handleClick} />
                                </div>
                                <div className="col-6 pb-3 subicons">
                                    <img src="/img/rentshield-animation.svg" id='rentshield' alt="Rentshield Animation" className="w-100" onClick={handleClick} />
                                </div>
                            </div>
                        </div>

                    </div>

                </div>



                <div id="thirdAnimation" className={`subparts ${isVisible ? 'full-screen' : 'd-none'}`}>
                    <div className="tab-control">
                        <button type="button" className="btn close d-lg-none d-block" onClick={handleCloseButtonClick}>
                            <img src="/img/left-arrow.svg" alt="close btn" className="w-100" />
                        </button>
                        <div className="backicons d-flex justify-content-between">
                            <button type="button" className="btn backbtn d-lg-block d-none" onClick={handleCloseButtonClick}>
                                <img src="/img/backbtn.svg" alt="close btn" className="w-100" />
                            </button>
                            <button type="button" className="btn close d-lg-block d-none" onClick={handleCloseButtonClick}>
                                <img src="/img/closebtn.png" alt="close btn" className="w-100" />
                            </button>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row align-items-center">

                            <div className="col-md-12">
                                <Tabs
                                    activeKey={activeKey}
                                    onSelect={handleTabSelect}
                                    transition={false}
                                    id="centered-tab-example"
                                    className="d-flex justify-content-center mb-3"
                                >


                                    <Tab
                                        eventKey="home"
                                        title={
                                            <div className="tab-title-container">
                                                <img src={activeKey === 'home' ? '/img/1sticon-active.png' : '/img/1sticon.png'} alt="RentDirect Icon" />
                                                <span className="tab-tooltip"><span>Home</span></span>
                                            </div>
                                        }
                                    >
                                        <CompOne />
                                    </Tab>

                                    <Tab
                                        eventKey="RentDirect"
                                        title={
                                            <div className="tab-title-container">
                                                <img
                                                    src={hoveredTab === 'RentDirect' || activeKey === 'RentDirect' ? '/img/2ndicon-active.png' : '/img/2ndicon.png'}
                                                    alt="RentDirect Icon"
                                                    onMouseEnter={() => setHoveredTab('RentDirect')}
                                                    onMouseLeave={() => setHoveredTab(null)}
                                                />
                                                <span className="tab-tooltip"><span>rent</span>direct</span>
                                            </div>
                                        }
                                    >
                                        <RentDirect />
                                    </Tab>

                                    <Tab
                                        eventKey="Rentid"
                                        title={
                                            <div className="tab-title-container">
                                                <img
                                                    src={hoveredTab === 'Rentid' || activeKey === 'Rentid' ? '/img/3rdicon-active.png' : '/img/3rdicon.png'}
                                                    alt="Rentid Icon"
                                                    onMouseEnter={() => setHoveredTab('Rentid')}
                                                    onMouseLeave={() => setHoveredTab(null)}
                                                />
                                                <span className="tab-tooltip"><span>rent</span>ID</span>
                                            </div>
                                        }
                                    >
                                        <Rentid />
                                    </Tab>
                                    <Tab
                                        eventKey="rentshield"
                                        title={
                                            <div className="tab-title-container">
                                                <img
                                                    src={hoveredTab === 'rentshield' || activeKey === 'rentshield' ? '/img/4thicon-active.png' : '/img/4thicon.png'}
                                                    alt="rentshield Icon"
                                                    onMouseEnter={() => setHoveredTab('rentshield')}
                                                    onMouseLeave={() => setHoveredTab(null)}
                                                />
                                                <span className="tab-tooltip"><span>rent</span>shield</span>
                                            </div>
                                        }
                                    >
                                        <RentShield />
                                    </Tab>
                                    <Tab
                                        eventKey="RentSuccess"
                                        title={
                                            <div className="tab-title-container">
                                                <img
                                                    src={hoveredTab === 'RentSuccess' || activeKey === 'RentSuccess' ? '/img/5thicon-active.png' : '/img/5thicon.png'}
                                                    alt="RentSuccess Icon"
                                                    onMouseEnter={() => setHoveredTab('RentSuccess')}
                                                    onMouseLeave={() => setHoveredTab(null)}
                                                />
                                                <span className="tab-tooltip"><span>rent</span>success</span>
                                            </div>
                                        }
                                    >
                                        <RentSuccess />
                                    </Tab>
                                    <Tab
                                        eventKey="RentAcademy"
                                        title={
                                            <div className="tab-title-container">
                                                <img
                                                    src={hoveredTab === 'RentAcademy' || activeKey === 'RentAcademy' ? '/img/6thicon-active.png' : '/img/6thicon.png'}
                                                    alt="RentAcademy Icon"
                                                    onMouseEnter={() => setHoveredTab('RentAcademy')}
                                                    onMouseLeave={() => setHoveredTab(null)}
                                                />
                                                <span className="tab-tooltip"><span>rent</span>academy</span>
                                            </div>
                                        }
                                    >
                                        <RentAcademy />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AnimationSection;
