import React from "react";
import Header from "../../Component/Header/Header";

const Privacy = () => {
  return (
    <div>
      <div>
      <Header/>
        <main className="main">
          {/* Hero Section */}
          <section id="privacy" className="hero section">
            <div className="container-fluid px-0">
              <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <h1>Rentid Privacy Policy</h1>
                  <h2>Introduction </h2>
                  <p className="py-4">
                    At Rentid, protecting your privacy is a fundamental part of
                    our commitment to providing reliable and secure car rental
                    services. This Privacy Policy explains how we collect, use,
                    disclose, and protect your personal information when you
                    interact with our website, mobile app, and rental services.
                    We collect and use information about:
                  </p>
                  <ul>
                    <li>
                      Hosts using Rentid to manage their car rental business
                    </li>
                    <li>Guests who rent vehicles through Rentid</li>
                    <li>
                      Partners who provide services, support, and improvements
                      for Rentid users
                    </li>
                    <li>
                      Visitors to Rentid’s websites and anyone contacting Rentid
                      support
                    </li>
                  </ul>
                  <p>
                    This Privacy Policy helps you understand how we collect,
                    use, and share your personal information. We may update this
                    Privacy Policy as needed. Significant changes will be
                    communicated to you (e.g., through the Rentid platform or
                    via email).
                  </p>
                </div>

                <div className="col-12 py-5">
                  <h2 className="py-3">Our Values</h2>

                  <h3 className="pt-4">Your Information Belongs to You</h3>
                  <p>
                    We carefully analyze the types of information we need to
                    provide our services and aim to limit the information we
                    collect to only what we need. We delete or anonymize this
                    information when it is no longer needed. Our guiding
                    principle is that your information belongs to you, and we
                    use it solely for your benefit.
                  </p>

                  <h3 className="pt-4">
                    We Protect Your Information from Others
                  </h3>
                  <p>
                    We will not share your personal information with third
                    parties without your permission unless legally required. If
                    legally required to share your information, we will inform
                    you in advance unless forbidden by law.
                  </p>

                  <h3 className="pt-4">
                    We Help Hosts and Partners Meet Their Privacy Obligations
                  </h3>
                  <p>
                    We strive to build our products and services in a
                    privacy-friendly way, provide detailed documentation, and
                    respond to privacy-related questions to help our hosts and
                    partners meet their privacy obligations.
                  </p>
                </div>

                <div className="col-12 py-5">
                  <h2 className="py-3">Information We Collect</h2>
                  <p>
                    In the course of operating the Services, Rentid collects or
                    receives the following types of information, which may
                    include personal information:
                  </p>

                  <h3 className="pt-4">Contact Information</h3>
                  <p>
                    When you sign up to use the Services, join our email list,
                    enter a promotion, or participate in our referral program,
                    you may be required to provide us with personal information
                    about yourself, such as your name, address, phone number,
                    and email address (collectively, the “Contact Information”).
                    We do not collect any Contact Information from you when you
                    use our Services unless you provide us with the Contact
                    Information voluntarily.
                  </p>

                  <h3 className="pt-4">Billing Information</h3>
                  <p>
                    To submit rental transactions through our platform, you will
                    be required to provide certain information in addition to
                    the Contact Information noted above. This information may
                    include a debit card number, credit card number, billing
                    address, expiration date, security code, and similar
                    information (collectively, the “Billing Information”).
                  </p>

                  <h3 className="pt-4">Geolocational Information</h3>
                  <p>
                    To provide access to the Services while you are using an
                    electronic device, we automatically collect Geolocational
                    information from your mobile device, your wireless carrier,
                    or certain third-party service providers (“Geolocational
                    Information”). Collection of such Geolocational Information
                    occurs only when the Services are running on your mobile
                    device. You may control the collection of Geolocational
                    Information through the user settings on your device. You
                    may decline to allow us to collect such Geolocational
                    Information, in which case Rentid will not be able to
                    provide certain services to you. Please be sure to manage
                    your mobile device and privacy preferences on the Platform
                    on an ongoing basis.
                    <br />
                    <br />
                    Note that Rentid uses Google Maps and the Google Maps API as
                    part of our rental system. By using Rentid’s web, iOS, or
                    Android systems, you are also bound to the Google Maps APIs
                    Terms of Service.
                  </p>
                </div>

                <div className="col-12 py-5">
                  <h3 className="py-3">Other Information</h3>
                  <p>
                    In addition to the Contact Information, the Billing
                    Information, and the Geolocational Information, we may
                    collect additional information regarding you and your use of
                    our Services, your interactions with us and our advertising,
                    as well as information regarding your computer or other
                    devices used to access our Services (collectively, the
                    “Other Information”). Such Other Information may include:
                  </p>

                  <ul>
                    <li>
                      <b>From You: </b> Additional information about yourself
                      that you voluntarily provide to us, such as your rental
                      preferences, interests, and feedback.
                    </li>
                    <li>
                      <b>From Your Activity: </b> Information that we
                      automatically collect when you use the Services, such as
                      (i) information about the device from which you access the
                      Services (i.e., the type of device, the advertising
                      identifier (“IDFA” or “AdID”), the operating system and
                      version (for example iOS, Android, or Windows), your
                      carrier and network type (WiFi, 3G, 4G, LTE); (ii) your IP
                      addresses (which may consist of a static or dynamic IP
                      address and will sometimes point to a specific
                      identifiable computer or device); (iii) browser type and
                      language; (iv) referring and exit pages and URLs; (v) date
                      and time; (vi) amount of time spent on particular pages;
                      (vii) what features of the Services you use or visit; and
                      (viii) similar information concerning your use of the
                      Services.
                    </li>
                    <li>
                      <b>From Cookies and Similar Technology:</b> Like most
                      online services, we automatically receive standard
                      technical information when you connect with the Services.
                      We collect this information through browser cookies,
                      pixels, web server logs, web beacons, and similar
                      technologies. These features enable us to personalize your
                      experience with the Services, understand how you use them,
                      maintain a persistent session, and improve and further
                      develop the Services. Cookies are small packets of data
                      that a website stores on your computer’s hard drive so
                      that your computer will “remember” information about your
                      visit. We use cookies to help us collect Other Information
                      and to enhance your experience using the Services.
                      Specifically, we use cookies to authenticate users,
                      personalize your experience when using the Services,
                      analyze which features of the Services you use most
                      frequently, and measure and optimize advertising and
                      promotional effectiveness. To do this, we may use both
                      session cookies, which expire once you close your web
                      browser, and persistent cookies, which stay on your
                      computer until you delete them. If you do not want the
                      Services to place a cookie on your hard drive, you may be
                      able to turn that feature off on your computer or mobile
                      device. Please consult your Internet browser’s
                      documentation for information on how to do this. However,
                      if you decide not to accept cookies from us, the Services
                      may not function properly. When the Services are made
                      available to you through third-party sites, please be
                      aware that these other sites maintain their own policies
                      regarding cookies and the collection and use of
                      information. You are responsible for reviewing those
                      policies.
                    </li>
                    <li>
                      <b>From Other Sources:</b>Information that we collect or
                      receive from Facebook and/or other sites on which you have
                      used the Services in accordance with their terms of use
                      and privacy policies and applicable law.
                    </li>
                    <li>
                      <b>Third-Party Analytics:</b>We use third-party analytics
                      services (such as Google Analytics) to evaluate your use
                      of the Services, compile reports on activity, collect
                      demographic data, analyze performance metrics, and collect
                      and evaluate other information relating to the Services
                      and mobile and internet usage. These third parties use
                      cookies and other technologies to help analyze and provide
                      us with the data. By accessing and using the Services, you
                      consent to the processing of data about you by these
                      analytics providers in the manner and for the purposes set
                      out in this Privacy Policy. The information used by such
                      analytics services is generally at the aggregate level. To
                      the extent any such information is at the individual level
                      or is used for secondary marketing purposes, you may
                      opt-out of such collection or use by sending an email to
                      privacy@rentid.io.
                    </li>
                  </ul>
                </div>

                <div className="col-12 py-5">
                  <h2 className="py-3">How We Use Your Information</h2>
                  <p>
                    We utilize your personal information for the following
                    purposes:{" "}
                  </p>

                  <ul>
                    <li>
                      <b>Processing Rentals: </b>To manage and fulfill your
                      vehicle rental reservations, including vehicle delivery
                      and returns.
                    </li>
                    <li>
                      <b>Identity Verification: </b>To confirm your identity and
                      eligibility for renting vehicles, ensuring compliance with
                      legal and regulatory requirements.
                    </li>
                    <li>
                      <b>Payment Processing: </b>Handling payments, invoicing,
                      processing refunds, and resolving billing inquiries and
                      disputes.
                    </li>
                    <li>
                      <b>Communication:</b>Keeping you informed about your
                      rentals, service updates, promotional offers, and other
                      relevant information through email, SMS, and other
                      communication channels.
                    </li>
                    <li>
                      <b>Enhancing User Experience: </b>Analyzing usage patterns
                      and feedback to improve our website, mobile app, and
                      overall service functionality.
                    </li>
                    <li>
                      <b>Legal Compliance:</b>Adhering to applicable laws,
                      regulations, and contractual obligations, and responding
                      to legal processes and requests from law enforcement or
                      regulatory authorities.
                    </li>
                    <li>
                      <b>Research and Development: </b>Conducting research, data
                      analysis, and product development to enhance our services
                      and develop new features and offerings.
                    </li>
                    <li>
                      <b>Security and Fraud Prevention:</b>Monitoring and
                      ensuring the security of our services, detecting and
                      preventing fraud, and protecting against unauthorized
                      access and use of our systems.
                    </li>
                    <li>
                      <b>Personalization: </b>Customizing your experience based
                      on your preferences and interactions with our services,
                      including providing tailored recommendations and targeted
                      advertisements.
                    </li>
                    <li>
                      <b>Customer Support: </b>Providing support and assistance
                      with your inquiries, resolving issues, and improving our
                      customer service.
                    </li>
                    <li>
                      <b>Marketing and Promotions: </b>Sending you marketing
                      communications, promotional offers, newsletters, and
                      information about events and surveys, subject to your
                      consent and preferences.
                    </li>
                  </ul>
                </div>

                <div className="col-12 py-5">
                  <h2 className="py-3">Data Sharing and Disclosure</h2>
                  <p>
                    We may share, transfer, or disclose Contact Information,
                    Billing Information, Geolocational Information, and/or Other
                    Information if you consent to us doing so, as well as in the
                    following circumstances:{" "}
                  </p>

                  <ul>
                    <li>
                      <b>To Provide Our Services to You: </b>To provide you with
                      our Services, we need to share your name, phone number,
                      rental details, and, if you are ordering for delivery,
                      your physical address with the service providers involved
                      in, or providing services in connection with, such orders,
                      such as vehicle delivery services, IT service providers,
                      and other similar third parties. Such parties will use
                      such information in accordance with the terms of their
                      respective privacy policies.
                    </li>
                    <li>
                      <b>To Provide Our Services to Partners: </b>We may share
                      your Contact Information, Geolocational Information, and
                      Other Information with any third parties on behalf and at
                      the instruction of the partners we work with as part of
                      the services we provide to them.
                    </li>
                    <li>
                      <b>
                        To Administer Our Rewards, Referral, and Promotional
                        Programs:{" "}
                      </b>{" "}
                      In order to administer these programs, we may share your
                      Contact Information and Other Information with our service
                      providers and any other third parties related to such
                      rewards, referral, and promotional programs. However,
                      Information (including phone numbers) obtained from any
                      short code opt-in will not be shared with third parties
                      for their own marketing purposes.
                    </li>
                    <li>
                      <b>To Better Understand and Serve Our Customers: </b>In an
                      ongoing effort to better understand our users and our
                      Services, we might analyze Contact Information, Billing
                      Information, Geolocational Information, and/or Other
                      Information in aggregate form in order to operate,
                      maintain, manage, and improve the Services. This aggregate
                      information does not identify you personally. We may share
                      this aggregate data with our affiliates, agents, and
                      business partners. We may also disclose aggregated user
                      statistics in order to describe our products and services
                      to current and prospective business partners and to other
                      third parties for other lawful purposes.
                    </li>
                    <li>
                      <b>To Assist Us in Running Our Business: </b>We may employ
                      other companies and individuals to perform functions on
                      our behalf. Examples may include providing marketing
                      assistance, detecting and preventing fraudulent activity,
                      order fulfillment, billing, and customer service. These
                      other companies will have access to the Contact
                      Information, the Billing Information, the Geolocational
                      Information, and the Other Information only as necessary
                      to perform their functions and to the extent permitted by
                      law.
                    </li>
                    <li>
                      <b>To Receive Assistance with Our Advertising: </b>We
                      share Other Information about your activity in connection
                      with your use of the Services with third-party advertisers
                      and remarketers for the purpose of tailoring, analyzing,
                      managing, reporting, and optimizing advertising you see on
                      the Platform and elsewhere. These third parties may use
                      cookies, pixel tags (also called web beacons or clear
                      gifs), and/or other technologies to collect such Other
                      Information for such purposes. Pixel tags enable us, and
                      these third-party advertisers, to recognize a browser’s
                      cookie when a browser visits the site on which the pixel
                      tag is located in order to learn which advertisement
                      brings a user to a given site. By accessing and using the
                      Services, you consent to the processing of data about you
                      by these advertisers/remarketing providers in the manner
                      and for the purposes set out in this Privacy Policy.
                    </li>
                    <li>
                      <b>
                        As Part of the Sale or Other Transfer of Our Business:{" "}
                      </b>
                      As we develop our business, we might sell or buy
                      businesses or assets. In the event of a corporate sale,
                      merger, reorganization, sale of assets, dissolution,
                      investment, or similar corporate event, the Contact
                      Information, Billing Information, Geolocational
                      Information, and Other Information may be part of the
                      transferred assets.
                    </li>
                    <li>
                      <b>For Legal Purposes: </b>To the extent permitted by law,
                      we may also disclose Contact Information, Billing
                      Information, Geolocational Information, and Other
                      Information when required by law, court order, or other
                      government or law enforcement authority or regulatory
                      agency, or whenever we believe that disclosing such
                      information is necessary or advisable, for example, to
                      protect the rights, property, or safety of Rentid or
                      others.{" "}
                    </li>
                  </ul>
                  <p>
                    We will take reasonable measures to require that any party
                    receiving any of your personal information from us
                    undertakes to: (i) retain and use such information only for
                    the purposes set out in this Privacy Policy; (ii) not
                    disclose your personal information except with your consent,
                    as permitted by law, or as permitted by this Privacy Policy;
                    and (iii) generally protect the privacy of your personal
                    information.{" "}
                  </p>
                </div>

                <div className="col-12 py-5">
                  <h2 className="py-3">Cookies and Tracking Technologies</h2>
                  <p>
                    We use cookies and similar tracking technologies on our
                    website and services to:
                  </p>

                  <ul>
                    <li>
                      <b>Enhance User Experience: </b>Cookies help us remember
                      your preferences, improve website functionality, and
                      provide a more personalized experience.
                    </li>
                    <li>
                      <b>Analyze Usage Patterns: </b>We use cookies to collect
                      data on how users interact with our website and services,
                      which helps us understand usage trends and identify areas
                      for improvement.
                    </li>
                    <li>
                      <b>Provide Targeted Advertisements: </b> Cookies and
                      tracking technologies enable us to deliver relevant
                      advertisements based on your interests and browsing
                      behavior.
                    </li>
                    <li>
                      <b>Ensure Security: </b>Cookies help us detect and prevent
                      security threats and unauthorized access to our systems.
                    </li>
                    <li>
                      <b>Improve Marketing Efforts: </b>Tracking technologies
                      help us measure the effectiveness of our marketing
                      campaigns and optimize our promotional strategies.
                    </li>
                  </ul>
                  <p>
                    By using our website and services, you consent to the use of
                    cookies and similar tracking technologies as described in
                    this Privacy Policy. You can manage your cookie preferences
                    through your browser settings or by using the cookie
                    management tools provided on our website.
                  </p>
                  <p>
                    When you access our website or services, we and our
                    authorized third parties may automatically record certain
                    information (“log data”), including information that your
                    browser sends whenever you visit our website. This log data
                    may include the web address you came from or are going to,
                    your device model, operating system, browser type, unique
                    device identifier, IP address, mobile network carrier, and
                    time zone or approximate location.
                  </p>
                </div>

                <div className="col-12 py-5">
                  <h2 className="py-3">Third-Party Tools</h2>
                  <p>
                    We use third-party tools such as Google Analytics to analyze
                    website traffic and user behavior. These tools may collect
                    information such as your IP address, device type, browser
                    type, and pages visited. This information helps us
                    understand how our website is used and how we can improve
                    it. You can learn more about how Google Analytics collects
                    and processes data by visiting the Google Analytics Privacy
                    Policy.{" "}
                  </p>
                </div>

                <div className="col-12 py-5">
                  <h2 className="py-3">Data Security</h2>
                  <p>
                    We employ industry-standard security measures to safeguard
                    your personal information from unauthorized access,
                    disclosure, alteration, or destruction. These measures
                    include encryption, secure servers, and access controls.
                    However, please note that no method of transmission over the
                    Internet or electronic storage is entirely secure.{" "}
                  </p>
                </div>

                <div className="col-12 py-5">
                  <h2 className="py-3">Retention Period</h2>
                  <p>
                    We retain your personal information for as long as is
                    reasonably necessary to fulfill the purposes for which the
                    data was collected, to perform our contractual and legal
                    obligations, and for any applicable statute of limitations
                    periods for the purposes of bringing and defending claims.{" "}
                  </p>
                </div>

                <div className="col-12 py-5">
                  <h2 className="py-3">Users Outside the USA</h2>
                  <p>
                    Our application and database servers are located in the
                    United States. If you are an individual located in the
                    European Economic Area, the United Kingdom, Canada, or
                    another jurisdiction outside of the United States with laws
                    and regulations governing personal data collection, use, and
                    disclosure that differ from United States laws, please be
                    aware that information we collect will be processed and
                    stored in the United States or in other countries where we
                    or our third-party Service Providers have operations. By
                    submitting your personal data to Rentid and using Rentid,
                    you expressly consent to have your personal data transferred
                    to, processed, and stored in the United States or another
                    jurisdiction which may not offer the same level of privacy
                    protection as those in the country where you reside or are a
                    citizen.{" "}
                  </p>
                </div>

                <div className="col-12 py-5">
                  <h2 className="py-3">Compliance with Regional Laws</h2>
                  <p>
                    We are committed to complying with all applicable regional
                    privacy laws. As regulations evolve, we will update our
                    practices to ensure compliance with specific regional
                    requirements. This includes but is not limited to compliance
                    with the General Data Protection Regulation (GDPR) for users
                    in the European Economic Area and the California Consumer
                    Privacy Act (CCPA) for users in California.{" "}
                  </p>
                </div>

                <div className="col-12 py-5">
                  <h2 className="py-3">Your Privacy Rights</h2>
                  <p>
                    Depending on your location, you may have certain rights
                    regarding your personal information, including:
                  </p>

                  <ul>
                    <li>
                      <b>The right to access: </b>You have the right to request
                      access to the personal data we hold about you.{" "}
                    </li>
                    <li>
                      <b>The right to rectification: </b>You have the right to
                      request that we correct any information you believe is
                      inaccurate or complete information you believe is
                      incomplete.
                    </li>
                    <li>
                      <b>The right to erasure: </b> You have the right to
                      request that we delete your personal data under certain
                      circumstances.
                    </li>
                    <li>
                      <b>The right to restrict processing: </b>You have the
                      right to request that we restrict the processing of your
                      personal data under certain conditions.
                    </li>
                    <li>
                      <b>The right to data portability: </b>You have the right
                      to request that we transfer the data we have collected to
                      another organization, or directly to you, under certain
                      conditions.
                    </li>
                    <li>
                      <b>The right to object to processing: </b>You have the
                      right to object to our processing of your personal data,
                      under certain conditions.
                    </li>
                    <li>
                      <b>The right to opt-out of marketing communications: </b>
                      You can opt out of receiving marketing communications from
                      us at any time by following the unsubscribe instructions
                      included in these communications.
                    </li>
                    <li>
                      <b>The right to withdraw consent: </b> If we are
                      processing your personal data based on your consent, you
                      have the right to withdraw your consent at any time.
                      Withdrawing your consent will not affect the lawfulness of
                      the processing conducted prior to the withdrawal.
                    </li>
                  </ul>
                  <p>
                    To exercise any of these rights, please contact us at
                    privacy@rentid.io. We may need to verify your identity
                    before processing your request. We will respond to your
                    request within the applicable legal time frame. For
                    California residents, you have additional rights under the
                    California Consumer Privacy Act (CCPA), including the right
                    to opt-out of the sale of your personal data. For more
                    information, please refer to the “Additional California
                    Resident Privacy Disclosures” section of this Privacy
                    Policy.
                  </p>
                  <p>
                    For European Economic Area (EEA) residents, you have
                    additional rights under the General Data Protection
                    Regulation (GDPR). For more information, please refer to the
                    “Users Outside the USA” section of this Privacy Policy.{" "}
                  </p>
                </div>

                <div className="col-12 py-5">
                  <h2 className="py-3">Security and Storage of Information</h2>
                  <p>
                    Given the nature of communications and information
                    processing technology, there is no guarantee that Personal
                    Data will be absolutely safe from access, alteration, or
                    destruction by a breach of any of our physical, technical,
                    and managerial safeguards. However, Rentid takes the
                    security of your Personal Data very seriously. We work hard
                    to protect the Personal Data that you provide from loss,
                    misuse, unauthorized access, or disclosure and we have taken
                    reasonable steps to help protect the Personal Data we
                    collect.{" "}
                  </p>
                  <p>
                    You should also take steps to protect against unauthorized
                    access to your device and account by, among other things,
                    choosing a unique and complex password that nobody else
                    knows or can easily guess and keeping your log-in and
                    password private. We are not responsible for any lost,
                    stolen, or compromised passwords or for any activity on your
                    account via unauthorized password activity.{" "}
                  </p>
                  <p>
                    We retain the Personal Data we collect for as long as is
                    reasonably necessary to fulfill the purposes for which the
                    data was collected, to perform our contractual and legal
                    obligations (including any exemptions or exceptions
                    contemplated by law), and for any applicable statute of
                    limitations periods for the purposes of bringing and
                    defending claims.
                  </p>
                </div>

                <div className="col-12 py-5">
                  <h2 className="py-3">
                    Additional California Resident Privacy Disclosures
                  </h2>
                  <p>
                    Under the California Consumer Privacy Act of 2018 and any
                    subsequent amendments including the California Privacy
                    Rights Act of 2020 (collectively, “CCPA”), California
                    residents are entitled to the following additional
                    disclosures about our data processing. These disclosures
                    apply solely to Users, Visitors, and Guests who live in the
                    State of California (“California Residents”). All terms used
                    in this section have the same meaning as when used in the
                    CCPA. California Residents may also review our Notice at
                    Collection for our Website here.{" "}
                  </p>
                  <p>
                    In the preceding 12 months, we have collected the categories
                    of Personal Data: identifiers, personal information
                    categories listed in the California Customer Records statute
                    (Cal. Civ. Code § 1798.80(e)); commercial information; and
                    internet or other similar network activity. The purposes for
                    which we have collected Personal Data and the sources of
                    that information are described above in the Information we
                    Collect and the How We Use Your Information sections above.
                  </p>
                  <p>
                    In the preceding 12 months, we have disclosed Personal Data
                    for a business purpose as detailed in the With Whom We May
                    Share Your Information section above.{" "}
                  </p>
                  <p>
                    We do not knowingly collect the Personal Data of minors
                    under age 18.
                  </p>
                  <p>
                    For an explanation of the rights you may have as a
                    California resident, please see the Your Rights and Choices
                    section above. We will attempt to respond to California
                    Resident requests in as timely a fashion as possible. In the
                    event of delays over 45 days, we will inform you of the
                    reason and extension period in writing. If you have an
                    account with us, we may deliver our written response to that
                    account. Any disclosures we provide will only cover the
                    12-month period preceding the verifiable receipt of a
                    California Resident’s request unless you request otherwise
                    and that time period meets the regulatory requirements. The
                    response we provide will explain the reasons we cannot
                    comply with a request, if applicable.{" "}
                  </p>
                  <p>
                    We do not charge a fee to process or respond to your
                    verifiable consumer request unless it is excessive,
                    repetitive, or manifestly unfounded. If we determine that
                    the request warrants a fee, we will tell you why we made
                    that decision and provide you with a cost estimate before
                    fulfilling your request.{" "}
                  </p>
                  <p>
                    This Privacy Notice describes how we may share your
                    information, including for marketing purposes. California
                    residents are entitled to request and obtain from us once
                    per calendar year information about any of your Personal
                    Data shared with third parties for their own direct
                    marketing purposes, including the categories of information
                    and the names and addresses of those businesses with which
                    we have shared such information. To request this information
                    and for any other questions about our privacy practices and
                    compliance with California law, please contact us at
                    privacy@rentid.io.
                  </p>
                </div>

                <div className="col-12 py-5">
                  <h2 className="py-3">Changes to This Privacy Notice</h2>
                  <p>
                    This Privacy Notice is current as of the Effective Date set
                    forth above. This Privacy Notice may change if there is a
                    material change to the way information is handled at Rentid,
                    or to clarify our Notice or adjust clerical errors. If any
                    changes are made, we’ll post them on this page, so please be
                    sure to check back periodically. If you continue to use
                    Rentid after those changes are in effect, you agree to the
                    revised Privacy Notice.{" "}
                  </p>
                </div>

                <div className="col-12 py-5">
                  <h2 className="py-3">Contacting Us</h2>
                  <p>
                    If you have any questions or comments about this Privacy
                    Notice or anything related to privacy, please contact us via
                    email at privacy@rentid.io.{" "}
                  </p>
                  <p>You may also write to us at: </p>
                  <p> Attn: Rentid Privacy Department </p>
                  <p> 8 The Green </p>
                  <p> Suite 10988 </p>
                  <p> Dover, DE 19901 </p>
                  <p className="py-5"><b>Last updated: July 1, 2024
                  </b></p>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer id="footer" class="footer position-relative p-1">
          <ul className="footerlist">
            <li>
              <a href="privacy" target="_blank">
                Privacy Policy{" "}
              </a>
            </li>
            <li>
              <a href="terms" target="_blank">
                Terms of Service{" "}
              </a>
            </li>
          </ul>
        </footer>
      </div>
    </div>
  );
};

export default Privacy;
