import React from 'react'
import './DriverlessVehicleDelivery.css'

function DriverlessVehicleDelivery() {
    return (
        <>
            <section id="nine">
                <div className="container">
                    <div className="row align-items-center reverse-columns">
                        <div className="col-lg-5 col-md-5 px-1">
                            <div className="leftcard">
                                <img src="/img/rentid-logo.svg" width="w-100" alt="rentid logo" />
                                <h3 className="my-3">
                                    <span className="text-bg p-0">Driverless vehicle </span>&nbsp;<br />
                                    <span className="text-bg p-0"> delivery</span> - Yeah, we <br />
                                    said that right.
                                </h3>
                                <p className='mb-0'>Equip your current vehicle with tech to be <br />delivered directly to your guest.</p>
                                <p className="pt-3 mb-0">
                                    Learn more when you 
                                    sign up for <b>rentid Beta.</b>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7 p-0 video-bg">
                            <video
                                id="myVideo"
                                width="100%"
                                height="676"
                                autoPlay
                                muted
                                playsInline
                                loop
                                style={{ lineHeight: 0, display: 'block' }}
                            >
                                <source src="/img/Driverless-video1.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </section>

            <section id="delivery" className="">
                <div className="container">
                    <div className="row align-items-center delivery rounded-5 mx-1">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <h3 className="pb-2">
                                Save your spot <br />
                                for Driverless<br /> Vehicle Delivery.
                            </h3>
                            <p>Express your interest today!</p>
                            <a href="/beta-signup"><button type="button" className="btn btn-light">
                                Apply For Beta <i className="bi bi-arrow-right"></i>
                            </button></a>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 text-center pt-lg-0 pt-5">
                            <img src="/img/driverless-vehicle.webp" className="w-100" alt="Interested" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DriverlessVehicleDelivery