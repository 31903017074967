import React, { useState, useEffect } from 'react';
import { Field, ErrorMessage, useFormikContext } from "formik";
import axios from 'axios';
import * as Yup from 'yup';


const RentalBusiness = ({commaVal,setCommaVal}) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [isPlatformDropdownOpen, setIsPlatformDropdownOpen] = useState(false);
  const [isSoftwareDropdownOpen, setIsSoftwareDropdownOpen] = useState(false);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [selectedSoftware, setSelectedSoftware] = useState([]);
  const [customInputValues, setCustomInputValues] = useState({});
  const [platforms, setPlatforms] = useState([]);
  const [software, setSoftware] = useState([]);

  const [message,setMessage] = useState([])

// const [commaVal,setCommaVal]=useState("")


const { values, setFieldValue, errors, touched } = useFormikContext();
const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    // Fetch platform data from API
    axios.get('https://api.getrentid.io/api/beta/sharing-platforms', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    })
    .then(response => {
      setPlatforms(response.data.data);
    })
    .catch(error => {
      console.error('There was an error fetching the platforms!', error);
    });

    // Fetch software data from API
    axios.get('https://api.getrentid.io/api/beta/rental-softwares', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    })
    .then(response => {
      setSoftware(response.data.data);
    })
    .catch(error => {
      console.error('There was an error fetching the software!', error);
    });
  }, []);

  const handlePlatformCheckboxChange = (event) => {
    const value = event.target.value;
    console.log("Platform", value);
    setSelectedPlatforms((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  const handleSoftwareCheckboxChange = (event) => {
    const value = event.target.value;
    console.log("software", value);
    setSelectedSoftware((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  console.log("all links", commaVal);


  const platform = ''

  const validationSchema = Yup.object().shape({
    [`platform_option_${platform}`]: Yup.string()
      .required('This field is required')
  });



  const handleCustomInputChange = (event, platform) => {
    const value = event.target.value;
    
    setCustomInputValues(prevState => {
      const updatedValues = {
        ...prevState,
        [platform]: value
      };
      
      // console.log("All links value", updatedValues);
      // Create comma-separated string of all values
      const commaSeparatedValues = Object.values(updatedValues).join(',');
      
      setCommaVal(commaSeparatedValues);
      
      return updatedValues;
    });
  };



  // Dynamic class for the platform row based on selected platforms
  const platformRowClassName = `row platform ${selectedPlatforms.length > 0 ? 'platform-selected' : 'platform-not-selected'}`;

  // Dynamic class for the software row based on selected software
  const softwareRowClassName = `row software ${selectedSoftware.length > 0 ? 'software-selected' : 'software-not-selected'}`;

  const handleCheckChange = (event) => {
    const { value, checked } = event.target;
    const newValues = checked
      ? [...values.manage_business, value]
      : values.manage_business.filter((item) => item !== value);

      // const checkBoxValue = newValues.join(',');
      // console.log('New values array:', checkBoxValue);
      setFieldValue('manage_business', newValues);
  };


  return (
    <div className="h-100">
      <div className="row h-100 align-items-center">
        <div className="col-12 col-xs-12 col-md-6 px-0 m-auto">
          <div className="general-info-form py-2">
            <div className="row">
              <div className="col-12 p-0">
                <p className="llc-text mb-2">
                  How do you currently manage your rental business?
                </p>
                {/* <p className="llc-desc">Select a choice below</p> */}
              </div>
            </div>

            <div className="row">
            <div className="col-12 mt-3 p-0">
            <ErrorMessage name="manage_business" component="div" className="invalid-feedback" />
                <div className="form-check ps-0 position-relative">
                  <Field
                    type="checkbox"
                    id="option0"
                    name="manage_business[]"
                    value="0"
                    checked={values.manage_business.includes("0")}
                    className="form-check-input checkbox-right"
                    onChange={handleCheckChange}
                  />
                  <label className="form-check-label form-check-field w-100" htmlFor="option0">
                    <div className="row">
                      <div className="col-12 p-0">
                        <div className="row">
                          <div className="col-10 ps-0">
                            <img src="/img/beta-signup/like.svg" className="img-fluid" alt="just getting started" />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-12 ps-0">
                            <p className="llc-heading mb-0">Just getting started, still looking for a solution</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>

              <div className="col-12 mt-3 p-0">
                <div className="form-check ps-0 position-relative">
                  <Field
                    type="checkbox"
                    id="option1"
                    name="manage_business[]"
                    value="1"
                    checked={values.manage_business.includes("1")}
                    className="form-check-input checkbox-right"
                    onChange={handleCheckChange}
                  />
                  <label className="form-check-label form-check-field w-100" htmlFor="option1">
                    <div className="row">
                      <div className="col-12 p-0">
                        <div className="row">
                          <div className="col-10 ps-0">
                            <img src="/img/beta-signup/shaking-hands.svg" className="img-fluid" alt="someone else manages" />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-12 ps-0">
                            <p className="llc-heading mb-0">Someone else manages my rental business</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>

              <div className="col-12 mt-3 p-0">
                <div className="form-check ps-0 position-relative">
                  <Field
                    type="checkbox"
                    id="option2"
                    name="manage_business[]"
                    value="2"
                    checked={values.manage_business.includes("2")}
                    className="form-check-input checkbox-right"
                    onChange={handleCheckChange}
                  />
                  <label className="form-check-label form-check-field w-100" htmlFor="option2">
                    <div className="row">
                      <div className="col-12 p-0">
                        <div className="row">
                          <div className="col-10 ps-0">
                            <img src="/img/beta-signup/co-host.svg" className="img-fluid" alt="marketplace co-host" />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-12 ps-0">
                            <p className="llc-heading mb-0">I am a marketplace co-host</p>
                          </div>
                          {values.manage_business.includes("2") && (
                            <div className="input-wrapper px-0 pt-2">
                              <img src="/img/beta-signup/links.svg" className="img-fluid" alt="co-host detail" />
                              <Field
                                type="text"
                                className="form-control"
                                name="software_option_detail"
                                placeholder="Add link to your website"
                                required
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
           
            <div className={platformRowClassName}> {/* Use dynamic class here */}
              <div className="col-12 mt-3 p-0 position-relative">
                <div className="form-check ps-0">
                  <label className="form-check-label form-check-field w-100">
                    <div className="row">
                      <div className="col-12 p-0">
                        <div className="row">
                          <div className="col-10 ps-0">
                            <img
                              src="/img/beta-signup/Car-Share.svg"
                              className="img-fluid"
                              alt="car sharing platforms"
                            />
                          </div>
                          <div className="col-2 text-end px-0">
                            <Field
                              type="checkbox"
                              id="platformCheckbox"
                              name="manage_business[]"
                              value="3"
                              checked={selectedPlatforms.length > 0}
                              className={`form-check-input ${selectedPlatforms.length > 0 ? 'checked-icon' : 'unchecked-icon'}`}
                              onChange={() => setIsPlatformDropdownOpen(!isPlatformDropdownOpen)}
                            />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-12 ps-0">
                            <p className="llc-heading mb-0">
                              Car sharing platforms
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-12 p-0">
                        <div className="dropdown multiseclt-input-text">
                          <button
                            className="btn selectbtn w-100 d-flex justify-content-between align-items-center"
                            type="button"
                            onClick={() => setIsPlatformDropdownOpen(!isPlatformDropdownOpen)}
                          >
                            Select all that apply
                            <img
                              src="/img/downnarrow.svg"
                              className="img-fluid"
                              alt="llc"
                            />
                          </button>
                          {isPlatformDropdownOpen && (
                            <div className="dropdown-menu w-100 d-block position-relative">
                              {platforms.map((platform) => (
                                <div key={platform.id} className="form-check px-2 d-flex align-items-center checkbox-icon-field">
                                  <input
                                    type="checkbox"
                                    id={`platform-${platform.id}`}
                                    value={platform.name}
                                    checked={selectedPlatforms.includes(platform.name)}
                                    onChange={handlePlatformCheckboxChange}
                                    className="form-check-input me-2"
                                  />
                                  {platform.logo && (
                                    <img
                                      src={`/img/${platform.logo}`}
                                      alt={platform.name}
                                      className="me-2"
                                      style={{ width: '24px', height: '24px' }}
                                    />
                                  )}
                                  <label className="form-check-label" htmlFor={`platform-${platform.id}`}>
                                    {platform.name}
                                  </label>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        {selectedPlatforms.map((platform, index) => (
                          <div key={index} className="row mt-2">
                            <div className="col-12 p-0">
                              <div className="input-wrapper px-0 pt-2">
                                <img
                                  src="/img/beta-signup/links.svg"
                                  className="img-fluid"
                                  alt="someone else manages"
                                />
                                <Field
                                  type="text"
                                  className="form-control"
                                  name={`platform_option_${platform}`}
                                  value={customInputValues[platform] || ""}
                                  onChange={(e) => handleCustomInputChange(e, platform)}
                                  placeholder={`Add link to your ${platform} profile`}
                                  required
                                />
                                {/* <ErrorMessage name={`platform_option_${platform}`} component="div" className="invalid-feedback" /> */}

                                {/* <ErrorMessage
                                    name={`platform_option_${platform}`}
                                    component="div"
                                    className="text-danger"
                                  /> */}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <div className={softwareRowClassName}> {/* Use dynamic class here */}
              <div className="col-12 mt-3 p-0 position-relative">
                <div className="form-check ps-0">
                  <label className="form-check-label form-check-field w-100">
                    <div className="row">
                      <div className="col-12 p-0">
                        <div className="row">
                          <div className="col-10 ps-0">
                            <img
                              src="/img/beta-signup/Car-Share.svg"
                              className="img-fluid"
                              alt="car sharing software"
                            />
                          </div>
                          <div className="col-2 text-end px-0">
                            <Field
                              type="checkbox"
                              id="softwareCheckbox"
                              name="manage_business[]"
                              value="4"
                              checked={selectedSoftware.length > 0}
                              className={`form-check-input ${selectedSoftware.length > 0 ? 'checked-icon' : 'unchecked-icon'}`}
                              onChange={() => setIsSoftwareDropdownOpen(!isSoftwareDropdownOpen)}
                            />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-12 ps-0">
                            <p className="llc-heading mb-0">
                              Car sharing software
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-12 p-0">
                        <div className="dropdown multiseclt-input-text">
                          <button
                            className="btn selectbtn w-100 d-flex justify-content-between align-items-center"
                            type="button"
                            onClick={() => setIsSoftwareDropdownOpen(!isSoftwareDropdownOpen)}
                          >
                            Select all that apply
                            <img
                              src="/img/downnarrow.svg"
                              className="img-fluid"
                              alt="llc"
                            />
                          </button>
                          {isSoftwareDropdownOpen && (
                            <div className="dropdown-menu w-100 d-block position-relative">
                              {software.map((softwareItem) => (
                                <div key={softwareItem.id} className="form-check px-2 d-flex align-items-center checkbox-icon-field">
                                  <input
                                    type="checkbox"
                                    id={`software-${softwareItem.id}`}
                                    value={softwareItem.name}
                                    checked={selectedSoftware.includes(softwareItem.name)}
                                    onChange={handleSoftwareCheckboxChange}
                                    className="form-check-input me-2"
                                  />
                                  {softwareItem.logo && (
                                    <img
                                      src={`/img/${softwareItem.logo}`}
                                      alt={softwareItem.name}
                                      className="me-2"
                                      style={{ width: '24px', height: '24px' }}
                                    />
                                  )}
                                  <label className="form-check-label" htmlFor={`software-${softwareItem.id}`}>
                                    {softwareItem.name}
                                  </label>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        {selectedSoftware.map((softwareItem, index) => (
                          <div key={index} className="row mt-2">
                            <div className="col-12 p-0">
                            <div className="input-wrapper px-0 pt-2">
                                <img
                                  src="/img/beta-signup/links.svg"
                                  className="img-fluid"
                                  alt="someone else manages"
                                /> 
                              <Field
                                type="text"
                                className="form-control"
                                name={`software_option_${softwareItem}`}
                                value={customInputValues[softwareItem] || ""}
                                onChange={(e) => handleCustomInputChange(e, softwareItem)}
                                placeholder={`Add link to your ${softwareItem} profile`}
                                required
                              />
                              <ErrorMessage name={`software_option_${softwareItem}`} component="div" className="invalid-feedback" />
                              </div>
                            </div>  
                          </div>
                        ))}
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentalBusiness;
