import React from "react";
import { Field, ErrorMessage } from "formik";

const InsuranceOptions = () => {
  return (
    <div className="h-100">
      <div className="row h-100 align-items-center">
        <div className="col-12 col-xs-12 col-md-7 p-0 m-auto">
          <div className="general-info-form py-5">
            <div className="row">
              <div className="col-12 p-0">
                <p className="llc-text">
                  Would you be interested in taking<br/> advantage of Rentid’s
                  on-rent insurance<br/> options?
                </p>
                <p className="llc-desc">
                  Rentid offers A-rated on-rent insurance coverages and
                  protection plans
                </p>
              </div>
            </div>
            <div className="row">

              <div className="col-12 col-xs-12 col-md-6 p-0 pe-md-2">
              
                <div className="form-check ps-0">
                  <Field
                    type="radio"
                    name="on_rent_insurance"
                    value="1"
                    className="form-check-input"
                    id="securityCheck"
                  />
                  <label
                    className="form-check-label form-check-field w-100"
                    htmlFor="securityCheck"
                  >
                    <div className="row">
                      <div className="col-12 p-0">
                        <div className="row">
                          <div className="col-10 ps-0">
                            <img
                              src="/img/beta-signup/shield.svg"
                              className="img-fluid"
                              alt="llc"
                            />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-12 ps-0">
                            <p className="llc-heading mb-0">Absolutely</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="col-12 col-xs-12 col-md-6 mt-3 mt-md-0 p-0 ps-md-2">
                <div className="form-check ps-0">
                  <Field
                    type="radio"
                    name="on_rent_insurance"
                    value="0"
                    className="form-check-input"
                    id="securityCheck1"
                  />
                  <label
                    className="form-check-label form-check-field w-100"
                    htmlFor="securityCheck1"
                  >
                    <div className="row">
                      <div className="col-12 p-0">
                        <div className="row">
                          <div className="col-10 ps-0">
                            <img
                              src="/img/beta-signup/security-check.svg"
                              className="img-fluid"
                              alt="llc"
                            />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-12 ps-0">
                            <p className="llc-heading mb-0">
                              I need more information
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <ErrorMessage name="on_rent_insurance" component="div" className="invalid-feedback" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceOptions;
