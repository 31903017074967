import React, { useState, useEffect, useCallback } from 'react';
import './RentSuccess.css'
import Model from '../../utils/model';

const RentSuccess = () => {

    const [hoveredItem, setHoveredItem] = useState(null);

    const showUpdateRow = useCallback((item) => () => setHoveredItem(item), []);
    const hideUpdateRow = useCallback(() => setHoveredItem(null), []);
    const [show, setShow] = useState(false);
  
    const [content, setContent] = useState({})
  

    const items = [
        {
            icon: "/img/rentsuccess/customer-support.png",
            header: "Customer support",
            body: "Integrated Ai-Assisted, Marketing: Leverages advanced AI technology to optimize and personalize marketing strategies for maximum effectiveness and reach.",
            mainImage: "customer support.png"
        },
        {
            icon: "/img/rentsuccess/social-media.png",
            header: "Social Media",
            body: "Integrated Ai-Assisted, Marketing: Leverages advanced AI technology to optimize and personalize marketing strategies for maximum effectiveness and reach.",
            mainImage: "Social Media.png"
        },
        {
            icon: "/img/rentsuccess/tech.png",
            header: "Tech support",
            body: "Integrated Ai-Assisted, Marketing: Leverages advanced AI technology to optimize and personalize marketing strategies for maximum effectiveness and reach.",
            mainImage: "tech support.png"
        },
        {
            icon: "/img/rentsuccess/marketing.png",
            header: "Marketing",
            body: "Integrated Ai-Assisted, Marketing: Leverages advanced AI technology to optimize and personalize marketing strategies for maximum effectiveness and reach.",
            mainImage: "Protection Plan.png"
        }
    ];

    const handleShow = (item) => {
        console.log("drfghbjn", item)
        setContent(item)
        setShow(true)
    }

    return (
        <div>
            <div className="row rentsuccess-container">
                <div className="col-xl-4 col-lg-4 left-side">
                    <div className="row">
                        <div className="col-12 px-0">
                            <img className="logo" src="/img/rentsuccess.svg" alt="Rentid Logo" />
                            <h6 className="py-4 my-2">
                                24/7 SUPPORT AND ASSISTANCE
                            </h6>
                        </div>
                    </div>
                    <h2 className="mb-0">
                        Human and AI<br />  support. <br />
                        <span className="text-bg">You choose.</span>
                    </h2>

                    <p className="pt-lg-3 pt-4">
                        Tap into the rentid support team for you and your guests. We’re always available via chat, text, phone, or email, and powered by humans or robots for quicker service.
                    </p>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-8">
                    <div className="row d-lg-block d-none">
                        <div className="col-12 text-center px-0 position-relative">
                            <div className="row">
                                <div className="col-md-12">
                                    <img src="/img/rentsuccess/RENTSUCCESS.png" alt="Animation Image" className="mainImage" />
                                </div>

                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-6 mx-auto py-5 subicons">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className={`box1 ${hoveredItem && hoveredItem !== 'box1' ? 'blur' : ''}`} onMouseEnter={showUpdateRow('box1')} onMouseLeave={hideUpdateRow}>
                                                        <img src="/img/rentsuccess/customersupport.webp" alt="Animation Image" className={hoveredItem === 'box1' ? "icons" : ""} />
                                                        <div className={`hovertooltip ${hoveredItem === 'box1' ? 'show' : ''}`}>
                                                            <img src="/img/socialmedia.png" alt="Animation Image" />
                                                            <h5>Customer support</h5>
                                                            <p className='text-start'>
                                                                <b>Integrated Ai-Assisted, Marketing</b>: Leverages advanced AI technology to optimize and personalize marketing strategies for maximum effectiveness and reach.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className={`box2 ${hoveredItem && hoveredItem !== 'box2' ? 'blur' : ''}`} onMouseEnter={showUpdateRow('box2')} onMouseLeave={hideUpdateRow}>
                                                        <img src="/img/rentsuccess/SocialMedia.webp" alt="Animation Image" className={hoveredItem === 'box2' ? "mainImage" : ""} />
                                                        <div className={`hovertooltip ${hoveredItem === 'box2' ? 'show' : ''}`}>
                                                            <img src="/img/socialmedia.png" alt="Animation Image" />
                                                            <h5>Social Media</h5>
                                                            <p className='text-start'>
                                                                <b>Integrated Ai-Assisted, Marketing</b>: Leverages advanced AI technology to optimize and personalize marketing strategies for maximum effectiveness and reach.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className={`box3 ${hoveredItem && hoveredItem !== 'box3' ? 'blur' : ''}`} onMouseEnter={showUpdateRow('box3')} onMouseLeave={hideUpdateRow}>
                                                        <img src="/img/rentsuccess/techsupport.webp" alt="Animation Image" className={hoveredItem === 'box3' ? "mainImage" : ""} />
                                                        <div className={`hovertooltip ${hoveredItem === 'box3' ? 'show' : ''}`}>
                                                            <img src="/img/socialmedia.png" alt="Animation Image" />
                                                            <h5> Tech support</h5>
                                                            <p className='text-start'>
                                                                <b>Integrated Ai-Assisted, Marketing</b>: Leverages advanced AI technology to optimize and personalize marketing strategies for maximum effectiveness and reach.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className={`box4 ${hoveredItem && hoveredItem !== 'box4' ? 'blur' : ''}`} onMouseEnter={showUpdateRow('box4')} onMouseLeave={hideUpdateRow}>
                                                        <img src="/img/rentsuccess/ProtectionPlan.webp" alt="Animation Image" className={hoveredItem === 'box4' ? "mainImage" : ""} />
                                                        <div className={`hovertooltip ${hoveredItem === 'box4' ? 'show' : ''}`}>
                                                            <img src="/img/marketing.png" alt="Animation Image" />
                                                            <h5>Marketing</h5>
                                                            <p className='text-start'>
                                                                <b>Integrated Ai-Assisted, Marketing</b>: Leverages advanced AI technology to optimize and personalize marketing strategies for maximum effectiveness and reach.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

            <div className="row mobileview d-lg-none d-md-none d-block">

                <div className="col-12">
                    <img className="rentshieldlogo" src="/img/rentsuccess/rentsuccess-m.png" alt="Rentid Logo" />
                </div>
                <div className="col-12 pt-3">
                    <div className="row">
                        <div className="col-6">  <img className="paln" src="/img/rentsuccess/SocialMedia-m.png" alt="Rentid Logo" onClick={() => handleShow(items[1])} /></div>
                        <div className="col-6">  <img className="paln" src="/img/rentsuccess/techsupport-m.png" alt="Rentid Logo" onClick={() => handleShow(items[2])} /></div>
                    </div>
                </div>
                <div className="col-12 py-3">
                    <div className="row">
                        <div className="col-6">  <img className="paln" src="/img/rentsuccess/ProtectionPlan-m.png" alt="Rentid Logo" onClick={() => handleShow(items[3])} /></div>
                        <div className="col-6">  <img className="paln" src="/img/rentsuccess/customersupport-m.png" alt="Rentid Logo" onClick={() => handleShow(items[0])} /></div>
                    </div>
                </div>

            </div>
            <Model prop={{ show, setShow, content }} style={{ zIndex: 999 }} />
        </div>
    )
}

export default RentSuccess
