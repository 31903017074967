import React from 'react'
import './SupportTeam.css'
function SupportTeam() {
    return (
        <>
            <section id="office" className="">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <img src="/img/earth.webp" alt="Earth" className="w-100" />
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-8 col-lg-8 col-md-8">
                            <div className="row mt-4">
                                <div className="col-12 px-0 mt-2">
                                    <h6>YOUR DIGITAL SUPPORT TEAM </h6>
                                </div>
                            </div>
                            <h2 className="mt-2">
                                The <span>world's first</span> rental <br />
                                back-office infrastructure
                            </h2>
                            <p className="pt-lg-2 pt-2">
                                Access our <span className="text-bg">done-for-you</span>{" "}
                                rental tasks so you
                                <br />
                                can focus on guests and growing revenue.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SupportTeam