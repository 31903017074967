import React from 'react'
import './SolutionSection.css'

function SolutionSection() {
    return (
        <>
            <section id="eleven" className="">
                <div className="container">
                    <div className="row align-items-center reverse-columns" id='fleet'>
                        <div className="col-xl-5 col-lg-5 col-md-5 px-1">
                            <h6 className="pb-4">THE PERFECT SOLUTION </h6>
                            <h3>Fleet Sizes</h3>
                            <p className="pt-2">
                                Whether you're just getting started or have years of <br />experience...we help
                                fleets ranging from one to<br /> hundreds of vehicles, across multiple branches.
                            </p>

                            <ul className="fleet">
                                <li>&lt; 5 Vehicles</li>
                                <li>5 - 25 Vehicles</li>
                                <li>25 - 100 Vehicles</li>
                                <li>100+ Vehicles</li>
                            </ul>
                        </div>

                        <div className="col-xl-7 col-lg-7 col-md-7 pb-lg-0 pb-4">
                            <img src="/img/fleet-img.webp" className="w-100" alt="Fleet" />
                        </div>
                    </div>

                    <div className="row align-items-center pt-4 mt-2"  id='partners'>
                        <div className="col-xl-6 col-lg-6 col-md-6 px-1">
                            <img src="/img/partner.webp" className="w-100" alt="Partner" />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 p-3">
                            <div className="rightcard p-5">
                                <h6 className="pb-4">SOMETHING FOR EVERYONE </h6>
                                <h3>Our Partners</h3>
                                <p className="pt-2">
                                    We're excited to partner with entrepreneurs who are <br />passionate about
                                    enhancing the customer experience<br /> and committed to generating revenue, efficiently.
                                </p>

                                <ul className="fleet">
                                    <li>Car Rental Agencies</li>
                                    <li>Service & Repair / Auto body</li>
                                    <li>Marketplace Hosts & Co-Hosts</li>
                                    <li>Pre-owned and New Auto Dealerships</li>
                                    <li>Hospitality and Parking Management Industries</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SolutionSection