import React, { useState, useEffect, useCallback } from 'react';
import './RentShield.css'
import { Col, Row } from 'react-bootstrap';
import Model from '../../utils/model';

const RentShield = () => {

    const [hoveredItem, setHoveredItem] = useState(null);

    const showUpdateRow = useCallback((item) => () => setHoveredItem(item), []);
    const hideUpdateRow = useCallback(() => setHoveredItem(null), []);
    const [show, setShow] = useState(false);

    const [content, setContent] = useState({})


    const items = [
        {
            icon: "/img/onrent-icon.png",
            header: "On-Rent Insurance",
            body: `Embedded On-Rent primary commercial auto insurance includes State Minimum Liability Insurance and Physical Damage coverage - both available for a competitive daily rate. Choose when to pay for coverage:
            No monthly cost; you only pay when the vehicle is rented.
            Guests can cover the cost of coverages through our Protection Plan, so you don't have to pay.
            If guest declines Protection Plan, you can automatically or manually select coverages you want.`
        },
        {
            icon: "/img/liability-icon.png",
            header: "Liability Insurance",
            body: "Stay compliant and protected with state mandatory insurance covering bodily injury and property damage to others."
        },
        {
            icon: "/img/protection-icon.png",
            header: "Protection Plans",
            body: "Roadside Assistance protection provides 24/7 support for guests, ensuring quick and reliable help for issues like breakdowns, flat tires, and lockouts."
        },
        {
            icon: "/img/physical-icon.png",
            header: "Physical Damage Insurance",
            body: "Our Physical Damage covers up to $90k of Actual Cash Value (ACV) - available for a competitive daily rate."
        },
        {
            icon: "/img/roadside-icon.png",
            header: "Roadside",
            body: "Roadside Assistance protection provides 24/7 support for guests, ensuring quick and reliable help for issues like breakdowns, flat tires, and lockouts."
        }
    ];

    const handleShow = (item) => {
        setContent(item)
        setShow(true)
    }

    return (
        <div>
            <div className="row rentshield">
                <div className="col-xl-4 col-lg-4 left-side">
                    <div className="row">
                        <div className="col-12 px-0">
                            <img className="logo" src="/img/rentshield.svg" alt="Rentid Logo" />
                            <h6 className="py-4 my-2">
                                EMBEDDED INSURANCE AND PROTECTION PLANS
                            </h6>
                        </div>
                    </div>
                    <h2 className="mb-0">
                        <span className="text-bg">Cover & protect</span> your<br />
                        revenue-generating  <br />
                        assets.
                    </h2>

                    <p className="pt-lg-3 pt-4">
                        Keep vehicles on the road with peace <br /> of mind, maintaining compliance, <br /> legality, and earning revenue.
                    </p>

                </div>
                <div className="col-xl-8 col-lg-8 col-md-8">
                    <div className="row d-lg-block d-none">
                        <div className="col-12 text-center px-0 position-relative">
                            <div className="row">
                                <div className="col-md-12">
                                    <img src="/img/rentshield/rent-shield.png" alt="Animation Image" className="mainImage" />
                                </div>

                          

                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-6 mx-auto py-5 subicons">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className={`box1 ${hoveredItem && hoveredItem !== 'box1' ? 'blur' : ''}`} 
                                                        onMouseEnter={showUpdateRow('box1')} onMouseLeave={hideUpdateRow}>
                                                        <img src="/img/rentshield/OnRentIns.webp" alt="Animation Image" className={hoveredItem === 'box1' ? "icons" : ""} />
                                                        <div className={`hovertooltip ${hoveredItem === 'box1' ? 'show' : ''}`}>
                                                            <img src="/img/onrent-icon.png" alt="Animation Image" />
                                                            <h5>On-Rent Insurance</h5>
                                                            <p className='text-start'>
                                                                Embedded On-Rent primary commercial auto insurance includes State Minimum Liability Insurance and Physical Damage coverage - both available for a competitive daily rate. Choose when to pay for coverage:
                                                            </p>
                                                            <p className='text-start'>
                                                                No monthly cost; you only pay when the vehicle is rented.
                                                            </p>
                                                            <p className='text-start'>
                                                                Guests can cover the cost of coverages through our Protection Plan, so you don't have to pay.
                                                            </p>
                                                            <p className='text-start'>
                                                                If guest declines Protection Plan, you can automatically or manually select coverages you want.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className={`box2 ${hoveredItem && hoveredItem !== 'box2' ? 'blur' : ''}`} 
                                                        onMouseEnter={showUpdateRow('box2')} onMouseLeave={hideUpdateRow}>
                                                        <img src="/img/rentshield/Liability.webp" alt="Animation Image" className={hoveredItem === 'box2' ? "mainImage" : ""} />
                                                        <div className={`hovertooltip ${hoveredItem === 'box2' ? 'show' : ''}`}>
                                                            <img src="/img/liability-icon.png" alt="Animation Image" />
                                                            <h5>Liability Insurance</h5>
                                                            <p className='text-start'>
                                                                Stay compliant and protected with state mandatory insurance covering bodily injury and property damage to others.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">                                                
                                                    <div className={`box3 ${hoveredItem && hoveredItem !== 'box3' ? 'blur' : ''}`} 
                                                        onMouseEnter={showUpdateRow('box3')} onMouseLeave={hideUpdateRow}>
                                                        <img src="/img/rentshield/ProtectionPlan.webp" alt="Animation Image" className={hoveredItem === 'box3' ? "mainImage" : ""} />
                                                        <div className={`hovertooltip ${hoveredItem === 'box3' ? 'show' : ''}`}>
                                                            <img src="/img/protection-icon.png" alt="Animation Image" />
                                                            <h5>Protection Plans</h5>
                                                            <p className='text-start'>
                                                                Roadside Assistance protection provides 24/7 support for guests, ensuring quick and reliable help for issues like breakdowns, flat tires, and lockouts.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 mx-auto subicons">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className={`box4 ${hoveredItem && hoveredItem !== 'box4' ? 'blur' : ''}`} 
                                                        onMouseEnter={showUpdateRow('box4')} onMouseLeave={hideUpdateRow}>
                                                        <img src="/img/rentshield/Physicaldamage.webp" alt="Animation Image" className={hoveredItem === 'box4' ? "mainImage" : ""} />
                                                        <div className={`hovertooltip ${hoveredItem === 'box4' ? 'show' : ''}`}>
                                                            <img src="/img/physical-icon.png" alt="Animation Image" />
                                                            <h5>Physical Damage Insurance</h5>
                                                            <p className='text-start'>
                                                                Our <b>Physical Damage</b> covers up to $90k of Actual Cash Value (ACV) - available for a competitive daily rate.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className={`box5 ${hoveredItem && hoveredItem !== 'box5' ? 'blur' : ''}`} 
                                                        onMouseEnter={showUpdateRow('box5')} onMouseLeave={hideUpdateRow}>
                                                        <img src="/img/rentshield/Roadside.webp" alt="Animation Image" className={hoveredItem === 'box5' ? "mainImage" : ""} />
                                                        <div className={`hovertooltip ${hoveredItem === 'box5' ? 'show' : ''}`}>
                                                            <img src="/img/roadside-icon.png" alt="Animation Image" />
                                                            <h5>Roadside</h5>
                                                            <p className='text-start'>
                                                                Roadside Assistance protection provides 24/7 support for guests, ensuring quick and reliable help for issues like breakdowns, flat tires, and lockouts.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

            <div className="row mobileview d-lg-none d-md-none d-block">

                <div className="col-12">
                    <div className="row">
                        <div className="col-12">
                            <img className="logo" src="/img/rentshield.svg" alt="Rentid Logo" />
                            <h6 className="py-2 py-md-4 my-2">
                                EMBEDDED INSURANCE AND PROTECTION PLANS
                            </h6>
                        </div>
                    </div>
                    <h2 className="mb-0">
                        <span className="text-bg">Cover & protect</span> your <br />
                        revenue-generating  <br />
                        assets.
                    </h2>

                    <p className="pt-lg-3 pt-4">
                        Keep vehicles on the road with peace <br /> of mind, maintaining compliance, <br /> legality, and earning revenue.
                    </p>

                </div>
                <div className="col-12">
                    <img className="rentshieldlogo" src="/img/rentshield/rentshield-m.png" alt="Rentid Logo" />
                </div>
                <div className="col-12 pt-3">
                    <div className="row">
                        <div className="col-6">  <img className="paln" src="/img/rentshield/Physicaldamage-m.png" alt="Rentid Logo" onClick={() => handleShow(items[3])} /></div>
                        <div className="col-6">  <img className="paln" src="/img/rentshield/Roadside-m.png" alt="Rentid Logo" onClick={() => handleShow(items[4])} /></div>
                    </div>
                </div>
                <div className="col-12 pt-3">
                    <div className="row">
                        <div className="col-6">  <img className="paln" src="/img/rentshield/Liability-m.png" alt="Rentid Logo" onClick={() => handleShow(items[1])} /></div>
                        <div className="col-6">  <img className="paln" src="/img/rentshield/ProtectionPlan-m.png" alt="Rentid Logo" onClick={() => handleShow(items[2])} /></div>
                    </div>
                </div>
                <div className="col-12 py-3">
                    <div className="row">
                        <div className="col-6">  <img className="paln" src="/img/rentshield/OnRentIns-m.png" alt="Rentid Logo" onClick={() => handleShow(items[0])} /></div>
                    </div>

                </div>
            </div>

            <Model prop={{ show, setShow, content }} style={{ zIndex: 999 }} />

        </div>


    )
}

export default RentShield
