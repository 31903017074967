import React from 'react'
import './CompOne.css'
const CompOne = () => {
  return (
    <div>
     
    </div>
  )
}

export default CompOne
