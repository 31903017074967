// UserContext.js
import React, { createContext, useState } from "react";

// Create the context
const UserContext = createContext();

// Create a provider component
const UserFormDataProvider = ({ children }) => {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
  });

  /*
  firstName: data.first_name,
            lastName: data.last_name,
            companyName: data.companyName,
*/
  // Update user information
  const updateUserFormData = (firstName, lastName, companyName ) => {
    console.log("firstName", firstName);
    setUserData({ firstName, lastName, companyName});
  };

  return (
    <UserContext.Provider value={{ userData, updateUserFormData }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserFormDataProvider };
