import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './model.css';

function Model({ prop }) {
    const handleClose = () => prop.setShow(false);

    return (
        <>
            <Modal show={prop.show} onHide={handleClose} centered className='model'>
                <Modal.Header closeButton className="no-border pb-0">
                   <img src={prop.content.icon} alt="Animation Image" className="centered-img" />
                </Modal.Header>
                <Modal.Body>
                <div>
                    <h5>{prop.content.header}</h5>
                    <p className='text-start'>
                        {prop.content.body}
                    </p>
                </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Model;
