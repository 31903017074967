import React from "react";
import { Field, ErrorMessage } from "formik";

const RentalCompanies = () => {
  return (
    <div className="h-100">
      <div className="row h-100 align-items-center">
        <div className="col-12 col-xs-12 col-md-7 px-0 m-auto">
          <div className="rental-companies-form">
            <div className="row">
              <div className="col-12 p-0">
                <p className="llc-text pb-2">
                  Are you familiar with state and local<br/> regulations for car rental companies?
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-xs-12 col-md-6 p-0 pe-md-2">
                <div className="form-check ps-0">
                  <Field
                    type="radio"
                    id="regulations_compliance"
                    name="regulations_compliance"
                    value="1"
                    className="form-check-input"
                  />
                  <label
                    className="form-check-label form-check-field w-100"
                    htmlFor="regulations_compliance"
                  >
                    <div className="row">
                      <div className="col-12 p-0">
                        <div className="row">
                          <div className="col-10 ps-0">
                            <img
                              src="/img/beta-signup/tick.svg"
                              className="img-fluid"
                              alt="yes"
                            />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-12 ps-0">
                            <p className="llc-heading mb-0">Yes</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="col-12 col-xs-12 col-md-6 mt-3 mt-md-0 p-0 ps-md-2">
                <div className="form-check ps-0">
                  <Field
                    type="radio"
                    id="not_regulations_compliance"
                    name="regulations_compliance"
                    value="0"
                    className="form-check-input"
                  />
                  <label
                    className="form-check-label form-check-field w-100"
                    htmlFor="not_regulations_compliance"
                  >
                    <div className="row">
                      <div className="col-12 p-0">
                        <div className="row">
                          <div className="col-10 ps-0">
                            <img
                              src="/img/beta-signup/Document-why.svg"
                              className="img-fluid"
                              alt="no"
                            />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-12 ps-0">
                            <p className="llc-heading mb-0">Not yet</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <ErrorMessage name="regulations_compliance">
              {(msg) => <div className="invalid-feedback">{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentalCompanies;
