import React from 'react'
import './StartFree.css'

function StartFree() {
    return (
        <>
            <section id="forteen" className="">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-12 col-md-12 text-center py-3">
                            <h3 className="mb-3">
                                It’s time to get <b>rentid - start for free</b>
                            </h3>
                            <h6>
                                Join our <span className="text-bg">beta test group</span> - there's only upside from here.
                            </h6>
                            <p className="py-3 d-flex justify-content-center">
                                <img className="timeicon" src="/img/time.svg" alt="" width="24" />
                                Takes less than 5 minutes
                            </p>
                            <a href="/beta-signup"><button type="button" className="btn btn-dark">Apply For Beta</button></a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default StartFree