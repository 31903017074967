import * as Yup from 'yup';

export const validationSchemas = [
  Yup.object({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    phone_number: Yup.string()
      .required('Phone number is required')
      .matches(
        /^\(\d{3}\) \d{3}-\d{4}$/,
        'Phone number is required'
      ),
    // email_address: Yup.string().email('Invalid email address').required('Email is required'),
    // email_address: Yup.string().email('Email address is not in the correct format please enter it correctly').required('Email is required'),
    email_address: Yup.string()
      .email('Email address is not in the correct format please enter it correctly')
      .required('Email is required')
      .test('has-period-after-at', 'Domain part must include a period.', value => {
        if (!value) return false;
        const [localPart, domainPart] = value.split('@');
        return domainPart && domainPart.includes('.');
      }),
    state: Yup.string().required('State is required'),
    fleet_size: Yup.string().required('Fleet size is required'),
  }),
  Yup.object({
    business_compliance: Yup.string().required('Business registration status is required'),
  }),
  Yup.object({
    regulations_compliance: Yup.string().required('Please select an option'),
  }),
  Yup.object({
    manage_business: Yup.array()
      .of(Yup.string())
      .min(1, 'Please select at least one brand')
  }),

  Yup.object({
    using_telematics: Yup.string().required('This field is required.'),
    selected_telematics: Yup.mixed().when("using_telematics", {
      is: (vesselCategoryCode) => vesselCategoryCode ==0,
      then: () => Yup.array().min(1, 'Please select at least one telematics device.').required(),
      otherwise:() => Yup.array().notRequired('This field is  not required.'),
    }) 
  }),

  Yup.object({
    automated_verification: Yup.string().required('ID verification selection is required'),
  }),
  Yup.object({
    automated_validation: Yup.string().required('Please select an option'),
  }),
  Yup.object({
    marketing_services: Yup.string().required('Please select an option'),
  }),
  Yup.object({
    renting_vehicles: Yup.string().required('Please select an option for vehicle rental interest'),
  }),
  Yup.object({
    insurance_provider: Yup.string().required('This field is required.'),
    selected_insurance_providers: Yup.mixed().when("insurance_provider", {
      is: (vesselCategoryCode) => vesselCategoryCode ==0,
      then: () => Yup.array().min(1, 'Please select at least one').required(),
      otherwise:() => Yup.array().notRequired('This field is  not required.'),
    }) 
  }),
  Yup.object({
    on_rent_insurance: Yup.string().required('Please select an option'),
  }),
  Yup.object({
    learn_deliverless: Yup.string().required('Please select an option'),
  }),
];
